﻿/// Roboto ///
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src:
    local('Roboto Light'),
    local('roboto-light'),
    url('../../fonts/roboto-light.ttf') format('truetype'),
    url('../../fonts/roboto-light.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local('Roboto Regular'),
    local('roboto-regular'),
    url('../../fonts/roboto-regular.ttf') format('truetype'),
    url('../../fonts/roboto-regular.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src:
    local('Roboto Medium'),
    local('roboto-medium'),
    url('../../fonts/roboto-medium.ttf') format('truetype'),
    url('../../fonts/roboto-medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:
    local('Roboto Bold'),
    local('roboto-bold'),
    url('../../fonts/roboto-bold.ttf') format('truetype'),
    url('../../fonts/roboto-bold.woff') format('woff');
}

/// Roboto Condensed ///
@font-face {
  font-family: 'RobotoCondensed-Light';
  font-style: normal;
  font-weight: 500;
  src:
    local('RobotoCondensed Light'),
    local('roboto-condensed-light'),
    url('../../fonts/roboto-condensed-light.ttf') format('truetype'),
    url('../../fonts/roboto-condensed-light.woff') format('woff');
}

@font-face {
  font-family: 'RobotoCondensed-Regular';
  font-style: normal;
  font-weight: 500;
  src:
    local('RobotoCondensed Regular'),
    local('roboto-condensed-regular'),
    url('../../fonts/roboto-condensed-regular.ttf') format('truetype'),
    url('../../fonts/roboto-condensed-regular.woff') format('woff');
}

@font-face {
  font-family: 'RobotoCondensed-Bold';
  font-style: normal;
  font-weight: 700;
  src:
    local('RobotoCondensed Bold'),
    local('roboto-condensed-bold'),
    url('../../fonts/roboto-condensed-bold.ttf') format('truetype'),
    url('../../fonts/roboto-condensed-bold.woff') format('woff');
}

/// Codere's icons ///
@font-face {
  font-family: 'Codere-Icons';
  src: url('../../icons/codere-icon/codere-icon.eot');
  src:
    url('../../icons/codere-icon/codere-icon.eot#iefix') format('embedded-opentype'),
    url('../../icons/codere-icon/codere-icon.ttf') format('truetype'),
    url('../../icons/codere-icon/codere-icon.woff') format('woff'),
    url('../../icons/codere-icon/codere-icon.svg#CodereIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'highschool-90';
  font-style: normal;
  font-weight: normal;
  src:
    local('highschool-90'),
    local('highschool-90'),
    url('../../fonts/highschool-90.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-medium';
  font-style: normal;
  font-weight: 600;
  src:
    local('Roboto Medium'),
    local('roboto-medium'),
    url('../../fonts/roboto-medium.ttf') format('truetype'),
    url('../../fonts/roboto-medium.woff') format('woff');
}
