﻿.list-preheader {
  margin: 0;
  padding: 0;
  @include font(roboto-regular, title, bold);
  color: #212121;
}

ion-list {
  margin: 0;
  padding: 0;
  display: block;
  list-style-type: none;

  &.list-md {
    // TODO: Revisar qué afecta fuera de EVENTO PAGE (Fix para bug Sports 1581)
    // margin: -1px 0 16px !important;
  }

  .bulletList {
    margin: 1rem 1rem 1rem 2rem;
  }

  .item-block .item-inner {
    border-bottom: 0px !important;
  }

  /*&.events-list, &.payment-list, &.legal-list, &.ticket-list, &.card-list, &.user-list {
        @extend .has-x-margin-mobile;
    }*/

  &.events-list {
    margin-bottom: 0;

    .event-list-header {
      flex: 0 1 100%;
      min-height: 3rem;
      padding: 0;
      border: 0;
      margin: 0;

      ion-label {
        @include flex(inline-flex, flex-start, center, row, nowrap);
        margin: 0;
        padding: 0;
      }

      .section-title {
        display: inline-flex;
        flex: 2;
        justify-content: flex-start;
        order: 1;
        margin: 0;
        color: #434343;
        @include font(roboto-condensed-bold, title, normal);
      }

      .total-events {
        display: inline-flex;
        flex: 2;
        justify-content: flex-end;
        order: 2;
        @include font(roboto-condensed-regular, title, normal);

        .arrow {
          padding-left: $inner-gutter-mobile;
          @include font(roboto-condensed-bold, main, normal);
          vertical-align: middle;
          line-height: 1;
          color: #434343;
        }
      }
    }

    .event-select {
      margin: 0;
      padding: 0;
      border: 0 !important;

      .item-inner {
        padding: 0;
      }

      .select-text {
        @include font(roboto-condensed-bold, main, normal);
      }
    }
  }

  .events-list + .events-list {
    margin-top: $outer-gutter-mobile;
  }

  &.events-columns-desk {
    // @include flex(inline-block, space-between, flex-start, row, wrap);
    @include flex(flex, space-between, flex-start, row, wrap);
    // padding: 4px;

    @include breakpoint(desktop) {
    }
  }

  &.payment-list {
    .item:first-child {
      border: 0;
    }
  }

  &.payment-sublist {
    width: 100%;
    background-color: #fff;
    padding: $inner-gutter-mobile;
    border: 0 !important;
    margin: 0 0 $outer-gutter-mobile;
    margin-top: $inner-gutter-mobile;
    box-shadow: $item-shadow-mobile;

    &.has-x-margin-mobile {
      width: auto;
    }

    //mover a deposit online
    .sendForm {
      margin: $outer-gutter-mobile 0;
    }

    ion-item {
      width: 100%;
      padding: 0 $outer-gutter-mobile;
      /*margin-bottom: 23px !important;*/
      margin: 23px 0 0 0 !important;
      border-radius: $border-radius;
      background-color: #fff;
      border: 1px solid #9a9a9a !important;
      box-shadow: none;
    }

    .input-has-value.error {
      margin-bottom: 0 !important;
    }

    .buttonsLine {
      @include flex(inline-flex, flex-start, center, row, nowrap);

      ion-col {
        max-width: 25% !important;
      }

      padding: 0;
      padding-top: 0;
      margin-bottom: 0px !important;

      .button {
        width: 100%;
        height: 2.9rem;
        padding: $outer-gutter-mobile 0;
        margin: 0;
        border-radius: $border-radius;
        background: #fff;
        box-shadow: $button-shadow-mobile;
        @include font(roboto-condensed-regular, title, normal);
        color: #616161;
      }
    }

    //fin mover a deposit online
  }

  &.legal-list {
    background: transparent;

    .item:last-child {
      border: 0;
    }

    ion-list-header {
      border: 0;
      @include font(roboto-regular, title, normal);
      color: #616161;
      margin: 0;
      padding: 0 $outer-gutter-mobile;

      ion-label {
        margin: 0;
        padding: 0;
      }
    }
  }

  &.ticket-list {
    background: #fff;
    padding: $inner-gutter-mobile $outer-gutter-mobile;
    border-radius: $border-radius;
    box-shadow: $item-shadow-mobile;

    .ticket-list-header {
      min-height: 2rem;
      background: transparent;
      margin: 0;
      padding: 0;
      border-top: 0;
      border-bottom: 1px solid #dedede;

      //color: get-sb-color(secondary);
      &.is-receipt {
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        border-bottom-color: get-sb-color(secondary);

        ion-label {
          font-size: 1.7rem !important;
          color: get-sb-color(secondary);
        }
      }

      ion-label {
        margin: 0;
        @include font(roboto-condensed-regular, main-subtitle, normal);
        color: #7d7d7d;
      }
    }

    .exampleInput {
      @include flex(inline-flex, flex-start, center, row, nowrap);
    }
  }

  &.card-list {
    background: #fff;
    padding-left: 0;
    padding-right: $outer-gutter-mobile;
    border-radius: $border-radius;
    box-shadow: $item-shadow-mobile;

    &.card-options {
      border-top-width: 4px;
      border-top-style: solid;
      border-top-color: get-sb-color(secondary);
      border-radius: 0;
      padding-bottom: 12px;

      ion-avatar {
        background-color: #f3f3f3;
        height: auto;
        width: auto;

        i {
          font-size: 2.2rem;
          padding: 1.5rem;
        }
      }

      .item-inner {
        border-bottom: 1px solid #d6d6d6 !important;
        --padding-right: 0;
      }

      .card-option:last-child {
        .item-inner {
          border-bottom: 0 !important;
        }
      }
    }

    .card-list-header {
      min-height: 2rem;
      background: get-sb-color(grey-200);
      margin: 0;
      padding: $inner-gutter-mobile $outer-gutter-mobile;
      line-height: 36px;
      border: 0 !important;

      &.background-color-transparent {
        background: get-sb-color(transparent) !important;
      }

      .item-inner {
        border: 0 !important;
      }

      ion-label {
        margin: 0;
      }
    }
  }

  &.user-list {
    background: #fff;
    padding: 0;
    border-radius: $border-radius;
  }
}

:host-context(.platform-core) {
  ion-list {
    /*&.events-list, &.payment-list, &.legal-list, &.ticket-list, &.card-list, &.user-list, &.league-list {
            @extend .has-x-margin-pc;
        }*/

    &.league-list {
      &.is-highlight {
        ion-row {
          justify-content: flex-start;

          ion-col {
            max-width: 33%;
            margin-bottom: 0.3339%;
          }
        }
      }

      .league-list-header {
        flex: 0 1 100%;
        min-height: 3rem;
        padding: 0;
        border: 0;
        margin: 0 0 $inner-gutter-pc;
        background: transparent;
        @include border('bottom', 1px, solid, secondary);

        .input-wrapper {
          align-items: flex-end;
        }

        ion-label {
          @include flex(inline-flex, flex-start, center, row, nowrap);
          margin: 0;
          padding: 0;
        }

        h1 {
          margin: 0;
          @include border('bottom', 4px, solid, secondary);
          @include font(roboto-condensed-regular, title, bold);
          color: #212121;
        }
      }

      ion-row {
        justify-content: flex-start;

        ion-col {
          max-width: calc(25% - 0.3339%);
          margin-bottom: 0.3339%;
          margin-right: 0.3339%;
        }
      }
    }
  }
}
