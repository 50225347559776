@import 'barcode';

.sb-filter-line {
  position: absolute;
  bottom: 0;
  z-index: get-z-index(sm);
  width: 0;
  min-height: $border-width--md;
  max-height: $border-width--md;
  transition: all $transition-timing--sm ease-in-out;
}

.platform-core {
  [theme='lobby'] {
    .sb-filter-line {
      bottom: 0;
      min-height: $border-width--md;
      max-height: $border-width--md;
    }
  }
}

@import 'iframe';
