﻿///IONIC ICONS
[class^='icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  // font-family: $font-family-secondary;
  font-family: $font-family-ionic;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.codere-icon {
  font-family: $font-family-secondary;
  font-style: normal;
}
///IONIC ICONS
///IONIC ICONS

/// CODERE ICONS
/// Para actualizar la presente fuente de los iconos:
/// 1). Cotejar la nueva hoja de CSS con este documento.
/// 2). Buscar la coincidencia de las palabras clave de los selectores, sino probar con el código de los glifos.
/// 3). Eliminar las coincidencias en el nuevo CSS hasta dejar filtrados los restantes nuevos iconos.
/// 4). Añadir los nuevos iconos filtrados a este documento.

.icon-futbol-playa:before,
.icon-beach_soccer:before {
  content: '\e98c';
}
.icon-en-directo-missing:before {
  content: '\e98b';
}
.icon-default:before {
  content: '\e98a';
}
.icon-Archery:before {
  content: '\e973';
}
.icon-Athletics:before {
  content: '\e974';
}
.icon-bobsled:before {
  content: '\e975';
}
.icon-Commonwealth:before {
  content: '\e977';
}
.icon-Diving:before {
  content: '\e978';
}
.icon-Fencing:before {
  content: '\e97c';
}
.icon-Gymnastics:before {
  content: '\e97d';
}
.icon-Luge:before {
  content: '\e97e';
}
.icon-Modern-Pentathlon:before {
  content: '\e97f';
}
.icon-Poker:before {
  content: '\e980';
}
.icon-Shooting:before {
  content: '\e981';
}
.icon-Shorttrak:before {
  content: '\e982';
}
.icon-Skeleton:before {
  content: '\e983';
}
.icon-SkiJump:before {
  content: '\e984';
}
.icon-SpeedSkating:before {
  content: '\e985';
}
.icon-Swim:before {
  content: '\e986';
}
.icon-Triathlon:before {
  content: '\e987';
}
.icon-Weightfit:before {
  content: '\e988';
}
.icon-padel:before {
  content: '\e989';
}
.icon-rugbyLeague:before {
  content: '\e972';
}
.icon-VirtualSports:before {
  content: '\e96f';
}
.icon-Off-On:before {
  content: '\e976';
}
/// Cashier
.icon-Clock:before {
  content: '\e92c';
}
.icon-right:before {
  content: '\e94e';
}
.icon-wrong:before {
  content: '\e96a';
}
.icon-insert-coin:before {
  content: '\e96b';
}
.icon-take-coin:before {
  content: '\e96c';
}
.icon-photo:before {
  content: '\e96d';
}
.icon-visualisation:before {
  content: '\e98d';
}
.icon-Corner:before {
  content: '\e926';
}
.icon-Pitch:before {
  content: '\e97a';
}
.icon-Bat:before {
  content: '\e97b';
}
.icon-close-Circle:before {
  content: '\e969';
}
.icon-close:before {
  content: '\e968';
}
.icon-search:before {
  content: '\e967';
}
.icon-addfriend:before {
  content: '\e900';
}
.icon-alert:before {
  content: '\e901';
}
.icon-american_football:before {
  content: '\e902';
}
.icon-apuestas-83:before {
  content: '\e903';
}
.icon-apuestas-84:before {
  content: '\e904';
}
.icon-arrow-double:before {
  content: '\e905';
}
.icon-arrow-bottom:before {
  content: '\e906';
}
.icon-arrow-change-down:before {
  content: '\e907';
}
.icon-arrow-change-up:before {
  content: '\e908';
}
.icon-arrow-left:before {
  content: '\e909';
}
.icon-arrow-right:before {
  content: '\e90a';
}
.icon-arrow-top:before {
  content: '\e90b';
}
.icon-artes_marciales:before {
  content: '\e90c';
}
.icon-australian_football:before {
  content: '\e90d';
}
.icon-badminton:before {
  content: '\e90e';
}
.icon-basketball:before {
  content: '\e90f';
}
.icon-baseball:before {
  content: '\e910';
}
.icon-beach_volleyball:before {
  content: '\e911';
}
.icon-Billiard_Balls_Colored:before {
  content: '\e912';
}
.icon-blackjack:before {
  content: '\e913';
}
.icon-blackjack-crupier:before {
  content: '\e96e';
}
.icon-bowls:before {
  content: '\e914';
}
.icon-boxeo:before,
.icon-boxing:before {
  content: '\e915';
}
.icon-burger:before {
  content: '\e916';
}
.icon-callUs:before {
  content: '\e917';
}
.icon-cardPin:before {
  content: '\e918';
}
.icon-cardRec:before {
  content: '\e919';
}
.icon-cardSaldo:before {
  content: '\e91a';
}
.icon-casino:before {
  content: '\e91b';
}
.icon-charge-chat:before {
  content: '\e91c';
}
.icon-charge-Cloud:before {
  content: '\e91d';
}
.icon-checkmark:before {
  content: '\e91e';
}
.icon-cine:before {
  content: '\e91f';
}
.icon-cobros:before {
  content: '\e920';
}
.icon-contact:before {
  content: '\e921';
}
.icon-cricket:before {
  content: '\e922';
}
.icon-curling:before {
  content: '\e923';
}
.icon-cycling:before {
  content: '\e924';
}
.icon-darts:before {
  content: '\e925';
}
.icon-en-directo-scoreboard:before {
  content: '\e927';
}
.icon-en-directo-stream:before {
  content: '\e928';
}
.icon-esports:before {
  content: '\e929';
}
.icon-efootball:before {
  content: '\e990';
}
.icon-ebasket:before {
  content: '\e991';
}
.icon-athletics:before {
  content: '\e974';
}
.icon-favoritos:before {
  content: '\e92a';
}
.icon-floorball:before,
.icon-field_hockey:before {
  content: '\e92b';
}
.icon-soccer:before,
.icon-futbol:before {
  content: '\e92d';
}
.icon-futsal:before {
  content: '\e92e';
}
.icon-golf:before {
  content: '\e92f';
}
.icon-greyhound_racing:before {
  content: '\e930';
}
.icon-handball:before {
  content: '\e931';
}
.icon-history:before {
  content: '\e932';
}
.icon-home:before {
  content: '\e933';
}
.icon-horse_racing:before {
  content: '\e934';
}
.icon-calendar:before {
  content: '\e935';
}
.icon-ice_hockey:before {
  content: '\e936';
}
.icon-icono_avatar:before {
  content: '\e937';
}
.icon-icono_batalla_slots:before {
  content: '\e938';
}
.icon-karate:before {
  content: '\e939';
}
.icon-la-copa:before {
  content: '\e93a';
}
.icon-lastminute:before {
  content: '\e93b';
}
.icon-league:before {
  content: '\e93c';
}
.icon-list:before {
  content: '\e93d';
}
.icon-liveChat:before {
  content: '\e93e';
}
.icon-loteria:before {
  content: '\e93f';
}
.icon-mejora:before {
  content: '\e940';
}
.icon-menu:before {
  content: '\e941';
}
.icon-mobileCash:before {
  content: '\e942';
}
.icon-motorsport:before {
  content: '\e943';
}
.icon-multi:before {
  content: '\e944';
}
.icon-musica:before {
  content: '\e945';
}
.icon-olimpiadas:before {
  content: '\e946';
}
.icon-pelota:before {
  content: '\e947';
}
.icon-lock:before {
  content: '\e948';
}
.icon-play:before {
  content: '\e949';
}
.icon-pin:before {
  content: '\e94a';
}
.icon-poi:before {
  content: '\e94a';
}
.icon-politica:before {
  content: '\e94b';
}
.icon-preferencias:before {
  content: '\e94c';
}
.icon-promocion:before {
  content: '\e94d';
}
.icon-ral:before {
  content: '\e94f';
}
.icon-reCharge:before {
  content: '\e950';
}
.icon-reload:before {
  content: '\e951';
}
.icon-rugby:before,
.icon-rugby_league:before {
  content: '\e952';
}
.icon-rugbyunion:before {
  content: '\e953';
}
.icon-sailing:before {
  content: '\e954';
}
.icon-scan2:before {
  content: '\e955';
}
.icon-skiing:before {
  content: '\e956';
}
.icon-slots:before {
  content: '\e957';
}
.icon-snooker:before {
  content: '\e958';
}
.icon-apuestas_especiales:before {
  content: '\e959';
}
.icon-sub-promos:before {
  content: '\e95a';
}
.icon-sub-slots:before {
  content: '\e95b';
}
.icon-table_tennis:before {
  content: '\e95c';
}
.icon-tarjeta:before {
  content: '\e95d';
}
.icon-tennis:before {
  content: '\e95e';
}
.icon-ticket:before {
  content: '\e95f';
}
.icon-traineras:before {
  content: '\e960';
}
.icon-user-menu:before {
  content: '\e961';
}
.icon-video-bingo:before {
  content: '\e962';
}
.icon-videolive:before {
  content: '\e963';
}
.icon-virtual_soccer:before {
  content: '\e964';
}
.icon-volleyball:before {
  content: '\e965';
}
.icon-watercraftrowing:before {
  content: '\e966';
}
.icon-waterpolo:before {
  content: '\e979';
}

/// CODERE ICONS
//2020-04-30 squash,bowls-2
.icon-bowls-2:before {
  content: '\e970';
}
.icon-squash:before {
  content: '\e971';
}
.icon-aviator:before {
  content: '\e98e';
}
.icon-Iconos-app-codere-Expand_Icon-Statistics:before {
  content: '\e98c';
}
.icon-Iconos-app-codere-Expand_icon-en-directo-Missing:before {
  content: '\e98b';
}
.icon-icon-Iconos-app-codere-Expand_Icon-Default:before {
  content: '\e98a';
}
.icon-Iconos-app-codere-Expand_Icon-Visualisation:before {
  content: '\e98d';
}
.icon-Iconos-app-codere_Icon-Padel:before {
  content: '\e989';
}
.icon-Iconos-app-codere-Expand_Icon-Archery:before {
  content: '\e973';
}
.icon-Iconos-app-codere-Expand_Icon-Athletics:before {
  content: '\e974';
}
.icon-Iconos-app-codere-Expand_Icon-bobsled:before {
  content: '\e975';
}
.icon-Iconos-app-codere-Expand_Icon-Commonwealth:before {
  content: '\e977';
}
.icon-Iconos-app-codere-Expand_Icon-Diving:before {
  content: '\e978';
}
.icon-Iconos-app-codere-Expand_Icon-Fencing:before {
  content: '\e97c';
}
.icon-Iconos-app-codere-Expand_Icon-Gymnastics:before {
  content: '\e97d';
}
.icon-Iconos-app-codere-Expand_Icon-Luge:before {
  content: '\e97e';
}
.icon-Iconos-app-codere-Expand_Icon-Modern-Pentathlon:before {
  content: '\e97f';
}
.icon-Iconos-app-codere-Expand_Icon-Poker:before {
  content: '\e980';
}
.icon-Iconos-app-codere-Expand_Icon-Shooting:before {
  content: '\e981';
}
.icon-Iconos-app-codere-Expand_Icon-Shorttrak:before {
  content: '\e982';
}
.icon-Iconos-app-codere-Expand_Icon-Skeleton:before {
  content: '\e983';
}
.icon-Iconos-app-codere-Expand_Icon-SkiJump:before {
  content: '\e984';
}
.icon-Iconos-app-codere-Expand_Icon-SpeedSkating:before {
  content: '\e985';
}
.icon-Iconos-app-codere-Expand_Icon-Swim:before {
  content: '\e986';
}
.icon-Iconos-app-codere-Expand_Icon-Triathlon:before {
  content: '\e987';
}
.icon-Iconos-app-codere-Expand_Icon-Weightfit:before {
  content: '\e988';
}
.icon-Iconos-app-codere-Expand_icon-rugbyLeague:before {
  content: '\e972';
}
.icon-Iconos-app-codere-Expand_Icon-Squash:before {
  content: '\e971';
}
.icon-Iconos-app-codere-Expand_Icon-Bowls-2:before {
  content: '\e970';
}
.icon-Iconos-app-codere_icon-Off-On:before {
  content: '\e976';
}
.icon-Iconos-app-codere_Icon-VirtualSports:before {
  content: '\e96f';
}
.icon-Iconos-app-codere-Expand_icon-Clock:before {
  content: '\e92c';
}
.icon-Iconos-app-codere-Expand_icon-right:before {
  content: '\e94e';
}
.icon-Iconos-app-codere-Expand_icon-wrong:before {
  content: '\e96a';
}
.icon-Iconos-app-codere-Expand_icon-insert-coin:before {
  content: '\e96b';
}
.icon-Iconos-app-codere-Expand_icon-take-coin:before {
  content: '\e96c';
}
.icon-Iconos-app-codere-Expand_icon-photo:before {
  content: '\e96d';
}
.icon-Iconos-app-codere-Expand_icon-bj:before {
  content: '\e96e';
}
.icon-Iconos-app-codere-Expand_icon-Corner:before {
  content: '\e926';
}
.icon-Iconos-app-codere-Expand_icon-Pitch:before {
  content: '\e97a';
}
.icon-Iconos-app-codere-Expand_icon-Bat:before {
  content: '\e97b';
}
.icon-Iconos-app-codere-Expand_icon-close-Circle:before {
  content: '\e969';
}
.icon-Iconos-app-codere-Expand_icon-close:before {
  content: '\e968';
}
.icon-Iconos-app-codere-Expand_icon-search:before {
  content: '\e967';
}
.icon-Iconos-app-codere-Expand_icon-addfriend:before {
  content: '\e900';
}
.icon-Iconos-app-codere-Expand_icon-alert:before {
  content: '\e901';
}
.icon-Iconos-app-codere-Expand_icon-american_football:before {
  content: '\e902';
}
.icon-Iconos-app-codere-Expand_icon-apuestas-83:before {
  content: '\e903';
}
.icon-Iconos-app-codere-Expand_icon-apuestas-84:before {
  content: '\e904';
}
.icon-Iconos-app-codere-Expand_icon-arr-double:before {
  content: '\e905';
}
.icon-Iconos-app-codere-Expand_icon-arrow-bottom:before {
  content: '\e906';
}
.icon-Iconos-app-codere-Expand_icon-arrow-change-down:before {
  content: '\e907';
}
.icon-Iconos-app-codere-Expand_icon-arrow-change-up:before {
  content: '\e908';
}
.icon-Iconos-app-codere-Expand_icon-arrow-left:before {
  content: '\e909';
}
.icon-Iconos-app-codere-Expand_icon-arrow-right:before {
  content: '\e90a';
}
.icon-Iconos-app-codere-Expand_icon-arrow-top:before {
  content: '\e90b';
}
.icon-Iconos-app-codere-Expand_icon-artes_marciales:before {
  content: '\e90c';
}
.icon-Iconos-app-codere-Expand_icon-australian_football:before {
  content: '\e90d';
}
.icon-Iconos-app-codere-Expand_icon-badminton:before {
  content: '\e90e';
}
.icon-Iconos-app-codere-Expand_icon-baloncesto:before {
  content: '\e90f';
}
.icon-Iconos-app-codere-Expand_icon-baseball:before {
  content: '\e910';
}
.icon-Iconos-app-codere-Expand_icon-beach_volleyball:before {
  content: '\e911';
}
.icon-Iconos-app-codere-Expand_icon-Billiard_Balls_Colored:before {
  content: '\e912';
}
.icon-Iconos-app-codere-Expand_icon-blackjack:before {
  content: '\e913';
}
.icon-Iconos-app-codere-Expand_icon-bowls:before {
  content: '\e914';
}
.icon-Iconos-app-codere-Expand_icon-boxeo:before {
  content: '\e915';
}
.icon-Iconos-app-codere-Expand_icon-burger:before {
  content: '\e916';
}
.icon-Iconos-app-codere-Expand_icon-callUs:before {
  content: '\e917';
}
.icon-Iconos-app-codere-Expand_icon-cardPin:before {
  content: '\e918';
}
.icon-Iconos-app-codere-Expand_icon-cardRec:before {
  content: '\e919';
}
.icon-Iconos-app-codere-Expand_icon-cardSaldo:before {
  content: '\e91a';
}
.icon-Iconos-app-codere-Expand_icon-casino:before {
  content: '\e91b';
}
.icon-Iconos-app-codere-Expand_icon-charge-chat:before {
  content: '\e91c';
}
.icon-Iconos-app-codere-Expand_icon-charge-Cloud:before {
  content: '\e91d';
}
.icon-Iconos-app-codere-Expand_icon-checkmark:before {
  content: '\e91e';
}
.icon-Iconos-app-codere-Expand_icon-cine:before {
  content: '\e91f';
}
.icon-Iconos-app-codere-Expand_icon-cobros:before {
  content: '\e920';
}
.icon-Iconos-app-codere-Expand_icon-contact:before {
  content: '\e921';
}
.icon-Iconos-app-codere-Expand_icon-cricket:before {
  content: '\e922';
}
.icon-Iconos-app-codere-Expand_icon-curling:before {
  content: '\e923';
}
.icon-Iconos-app-codere-Expand_icon-cycling:before {
  content: '\e924';
}
.icon-Iconos-app-codere-Expand_icon-darts:before {
  content: '\e925';
}
.icon-Iconos-app-codere-Expand_icon-en-directo-scoreboard:before {
  content: '\e927';
}
.icon-Iconos-app-codere-Expand_icon-en-directo-stream:before {
  content: '\e928';
}
.icon-Iconos-app-codere-Expand_icon-esports:before {
  content: '\e929';
}
.icon-Iconos-app-codere-Expand_.icon-efootball:before {
  content: '\e990';
}
.icon-Iconos-app-codere-Expand_.icon-ebasket:before {
  content: '\e991';
}
.icon-Iconos-app-codere-Expand_.icon-athletics:before {
  content: '\e974';
}
.icon-Iconos-app-codere-Expand_icon-favoritos:before {
  content: '\e92a';
}
.icon-Iconos-app-codere-Expand_icon-field_hockey:before {
  content: '\e92b';
}
.icon-Iconos-app-codere-Expand_icon-futbol:before {
  content: '\e92d';
}
.icon-Iconos-app-codere-Expand_icon-futsal:before {
  content: '\e92e';
}
.icon-Iconos-app-codere-Expand_icon-golf:before {
  content: '\e92f';
}
.icon-Iconos-app-codere-Expand_icon-greyhound_racing:before {
  content: '\e930';
}
.icon-Iconos-app-codere-Expand_icon-handball:before {
  content: '\e931';
}
.icon-Iconos-app-codere-Expand_icon-history:before {
  content: '\e932';
}
.icon-Iconos-app-codere-Expand_icon-home:before {
  content: '\e933';
}
.icon-Iconos-app-codere-Expand_icon-horse_racing:before {
  content: '\e934';
}
.icon-Iconos-app-codere-Expand_icon-hoy:before {
  content: '\e935';
}
.icon-Iconos-app-codere-Expand_icon-ice_hockey:before {
  content: '\e936';
}
.icon-Iconos-app-codere-Expand_icon-icono_avatar:before {
  content: '\e937';
}
.icon-Iconos-app-codere-Expand_icon-icono_batalla_slots:before {
  content: '\e938';
}
.icon-Iconos-app-codere-Expand_icon-karate:before {
  content: '\e939';
}
.icon-Iconos-app-codere-Expand_icon-la-copa:before {
  content: '\e93a';
}
.icon-Iconos-app-codere-Expand_icon-lastminute:before {
  content: '\e93b';
}
.icon-Iconos-app-codere-Expand_icon-league:before {
  content: '\e93c';
}
.icon-Iconos-app-codere-Expand_icon-list:before {
  content: '\e93d';
}
.icon-Iconos-app-codere-Expand_icon-liveChat:before {
  content: '\e93e';
}
.icon-Iconos-app-codere-Expand_icon-loteria:before {
  content: '\e93f';
}
.icon-Iconos-app-codere-Expand_icon-mejora:before {
  content: '\e940';
}
.icon-Iconos-app-codere-Expand_icon-menu:before {
  content: '\e941';
}
.icon-Iconos-app-codere-Expand_icon-mobileCash:before {
  content: '\e942';
}
.icon-Iconos-app-codere-Expand_icon-motorsport:before {
  content: '\e943';
}
.icon-Iconos-app-codere-Expand_icon-multi:before {
  content: '\e944';
}
.icon-Iconos-app-codere-Expand_icon-musica:before {
  content: '\e945';
}
.icon-Iconos-app-codere-Expand_icon-olimpiadas:before {
  content: '\e946';
}
.icon-Iconos-app-codere-Expand_icon-pelota:before {
  content: '\e947';
}
.icon-Iconos-app-codere-Expand_icon-pin:before {
  content: '\e948';
}
.icon-Iconos-app-codere-Expand_icon-play:before {
  content: '\e949';
}
.icon-Iconos-app-codere-Expand_icon-poi:before {
  content: '\e94a';
}
.icon-Iconos-app-codere-Expand_icon-politica:before {
  content: '\e94b';
}
.icon-Iconos-app-codere-Expand_icon-preferencias:before {
  content: '\e94c';
}
.icon-Iconos-app-codere-Expand_icon-promocion:before {
  content: '\e94d';
}
.icon-Iconos-app-codere-Expand_icon-ral:before {
  content: '\e94f';
}
.icon-Iconos-app-codere-Expand_icon-reCharge:before {
  content: '\e950';
}
.icon-Iconos-app-codere-Expand_icon-reload:before {
  content: '\e951';
}
.icon-Iconos-app-codere-Expand_icon-rugby:before {
  content: '\e952';
}
.icon-Iconos-app-codere-Expand_icon-rugbyunion:before {
  content: '\e953';
}
.icon-Iconos-app-codere-Expand_icon-sailing:before {
  content: '\e954';
}
.icon-Iconos-app-codere-Expand_icon-scan2:before {
  content: '\e955';
}
.icon-Iconos-app-codere-Expand_icon-skiing:before {
  content: '\e956';
}
.icon-Iconos-app-codere-Expand_icon-slots:before {
  content: '\e957';
}
.icon-Iconos-app-codere-Expand_icon-snooker:before {
  content: '\e958';
}
.icon-Iconos-app-codere-Expand_Icon-SpecialMarkets:before {
  content: '\e959';
}
.icon-Iconos-app-codere-Expand_icon-sub-promos:before {
  content: '\e95a';
}
.icon-Iconos-app-codere-Expand_icon-sub-slots:before {
  content: '\e95b';
}
.icon-Iconos-app-codere-Expand_icon-table_tennis:before {
  content: '\e95c';
}
.icon-Iconos-app-codere-Expand_icon-tarjeta:before {
  content: '\e95d';
}
.icon-Iconos-app-codere-Expand_icon-tenis:before {
  content: '\e95e';
}
.icon-Iconos-app-codere-Expand_icon-ticket:before {
  content: '\e95f';
}
.icon-Iconos-app-codere-Expand_icon-traineras:before {
  content: '\e960';
}
.icon-Iconos-app-codere-Expand_icon-user-menu:before {
  content: '\e961';
}
.icon-Iconos-app-codere-Expand_icon-video-bingo:before {
  content: '\e962';
}
.icon-Iconos-app-codere-Expand_icon-videolive:before {
  content: '\e963';
}
.icon-Iconos-app-codere-Expand_icon-virtual_soccer:before {
  content: '\e964';
}
.icon-Iconos-app-codere-Expand_icon-volleyball:before {
  content: '\e965';
}
.icon-Iconos-app-codere-Expand_icon-watercraftrowing:before {
  content: '\e966';
}
.icon-Iconos-app-codere-Expand_icon-waterpolo:before {
  content: '\e979';
}

.icon-information-circle:before {
  content: '\f19f';
}
