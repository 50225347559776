%iframe {
  border: 0;
  outline: none;
}

.betsense-iframe {
  @extend %iframe;
  width: 100%;
  height: 95px;
}
