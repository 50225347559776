// Colors //
:root {
  --ion-color-codere-light: #ffffff;
  --ion-color-codere: #79c000;
  --ion-color-codere-rgb: 121, 192, 0;
  --ion-color-codere-contrast: #000000;
  --ion-color-codere-contrast-rgb: 0, 0, 0;
  --ion-color-codere-shade: #6aa900;
  --ion-color-codere-tint: #86c61a;
  --ion-color-codereg: #252a30;
  --ion-color-codereg-rgb: 36, 42, 48;
  --ion-color-codereg-contrast: #000000;
  --ion-color-codereg-contrast-rgb: 0, 0, 0;
  --ion-color-codereg-shade: #0f1312;
  --ion-color-codereg-tint: #2b2d2c;

  // gray
  --codere-grey-100: #f3f3f3;
  --codere-grey-200: #e8e8e8;
  --codere-grey-300: #e0e0e0;
  --codere-grey-400: #d3d3d3;
  --codere-grey-500: #adb2b5;
  --codere-grey-600: #999fa3;
  --codere-grey-700: #7f868b;
  --codere-grey-800: #5c656d;
  --codere-grey-900: #333f48;

  --codere-grey-700-transparent: rgba(200, 200, 200, 0.5);
  --codere-dark-02-transparent: rgba(0, 0, 0, 0.2);
}
.ion-color-codere {
  --ion-color-base: var(--ion-color-codere) !important;
  --ion-color-base-rgb: var(--ion-color-codere-rgb) !important;
  --ion-color-contrast: var(--ion-color-codere-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-codere-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-codere-shade) !important;
  --ion-color-tint: var(--ion-color-codere-tint) !important;
}
.ion-color-codereg {
  --ion-color-base: var(--ion-color-codereg) !important;
  --ion-color-base-rgb: var(--ion-color-codereg-rgb) !important;
  --ion-color-contrast: var(--ion-color-codereg-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-codereg-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-codereg-shade) !important;
  --ion-color-tint: var(--ion-color-codereg-tint) !important;
}

//Old map
$colors: (
  primary: #79c000
);
//Old map

// Sportbook's greys // NEW
$sb-grey-map: (
  grey-100: #f3f3f3,
  /// No tocar
  grey-200: #e8e8e8,
  /// No tocar
  grey-300: #e0e0e0,
  /// By Shai /// No tocar
  grey-400: #d3d3d3,
  grey-500: #adb2b5,
  /// By Shai /// No tocar
  grey-600: #999fa3,
  /// 30% by Shai
  grey-700: #7f868b,
  grey-700-transparent: rgba(200, 200, 200, 0.5),
  /// 50% by Shai
  grey-800: #5c656d,
  grey-900: #333f48 /// 100% Codere's grey /// By Shai /// No tocar /// All texts has to be this color
) !default;
// Sportbook's greys // NEW

// Sportbook's colors // NEW
$sb-color-map: (
  primary: #79c000,
  newprimary: #252a30,
  casprimary: #c546d3,
  secondary: #0056fe,
  accent: #0056fe,
  light: #ffffff,
  black: #000000,
  text-dark: map-get($sb-grey-map, grey-900),
  text-light: #ffffff,
  text-muted: map-get($sb-grey-map, grey-700),
  item-grey: map-get($sb-grey-map, grey-100),
  border-dark: map-get($sb-grey-map, grey-900),
  border-regular: map-get($sb-grey-map, grey-500),
  border-light: map-get($sb-grey-map, grey-800),
  border-button: map-get($sb-grey-map, grey-300),
  border: map-get($sb-grey-map, grey-400),
  background-badge: #000000,
  background-dark: map-get($sb-grey-map, grey-900),
  background-regular: map-get($sb-grey-map, grey-400),
  background-light: map-get($sb-grey-map, grey-300),
  background-base: map-get($sb-grey-map, grey-500),
  background-button: #ffffff,
  background-muted: map-get($sb-grey-map, grey-200),
  background-sets: rgba(black, 0.6),
  background-sets-columns: rgba(black, 0.25),
  background-scoreboards: #669900,
  background-casino-live: rgba(black, 0.8),
  background: map-get($sb-grey-map, grey-500),
  lobby-navbar-desktop: #3d4952,
  lobby-navbar-desktop-cas: #252a30,
  button-hover: #b5d979,
  green-dark: #037500,
  red-regular: #ff0000,
  red-dark: #ed1c24,
  orange: #ff9800,
  yellow-regular: #fff600,
  transparent: transparent,
  jackpot-wrapper-background: #1b133c,
  jackpot-header-background: #0b0b1a,
  jackpot-blue: #66f1f7,
  jackpot-blue-shadow: #0087ff,
  jackpot-pink: #ff83ff,
  //jackpot-purple:           #ba30dd,
  jackpot-purple: #9b0fbb,
  jackpot-yellow: #ffff29,
  jackpot-yellow-shadow: #ffa700,
  hovering-light: #e1e9fa,
  cas-layout: #AF64DB,
  cas-layout2: #4B3860
);
// Sportbook's colors // NEW

$sb-colors: map-merge($sb-color-map, $sb-grey-map);

// Maps just for modifiers // NEW
$sb-modifiers-colors: (
  primary: map-get($sb-colors, primary),
  secondary: map-get($sb-colors, secondary),
  accent: map-get($sb-colors, accent),
  yellow: map-get($sb-colors, yellow-regular),
  light: map-get($sb-colors, light),
  dark: map-get($sb-colors, grey-900),
  muted: map-get($sb-colors, grey-700),
  grey-100: map-get($sb-colors, grey-100),
  grey-200: map-get($sb-colors, grey-200),
  grey-300: map-get($sb-colors, grey-300),
  grey-400: map-get($sb-colors, grey-400),
  grey-500: map-get($sb-colors, grey-500),
  grey-600: map-get($sb-colors, grey-600),
  grey-700: map-get($sb-colors, grey-700),
  grey-800: map-get($sb-colors, grey-800),
  grey-900: map-get($sb-colors, grey-900),
  score: map-get($sb-colors, grey-500),
  alert: map-get($sb-colors, red-regular),
  progress: map-get($sb-colors, orange),
  lost: map-get($sb-colors, black)
);

$sb-modifiers-backgrounds: (
  primary: map-get($sb-colors, primary),
  secondary: map-get($sb-colors, secondary),
  yellow: map-get($sb-colors, yellow-regular),
  white: map-get($sb-colors, light),
  base: map-get($sb-colors, grey-500),
  light: map-get($sb-colors, grey-300),
  regular: map-get($sb-colors, grey-400),
  dark: map-get($sb-colors, grey-900),
  button: map-get($sb-colors, light),
  muted: map-get($sb-colors, grey-200),
  transparent: map-get($sb-colors, transparent)
);

$sb-modifiers-borders: (
  primary: map-get($sb-colors, primary),
  secondary: map-get($sb-colors, secondary),
  light: map-get($sb-colors, grey-800),
  regular: map-get($sb-colors, grey-500),
  dark: map-get($sb-colors, grey-900),
  grey-200: map-get($sb-colors, grey-200),
  button: map-get($sb-colors, grey-300),
  white: map-get($sb-colors, light)
);
// Maps just for modifiers // NEW

//Overwrite Ionic colors

$toolbar-background: #79c000 !default;
$toolbar-text-color: #ffffff !default;
$background-color: map-get($sb-colors, grey-500);
$content-ios-transition-background: map-get($sb-colors, grey-500);

//Overwrite Ionic colors

// Colors //

/// Deprecated

/// DEPRECATED
$font-size: (
  big-claim: 4rem,
  number: 3.4rem,
  hero-ad: 3rem,
  super-hero: 2rem,
  hero: 1.7rem,
  main: 1.5rem,
  main-subtitle: 1.3rem,
  title: 1.4rem,
  pretitle: 1.2rem,
  subtitle: 1.3rem,
  text: 1.3rem,
  base: 1rem
);
/// DEPRECATED

$font-family: (
  roboto: 'Roboto',
  roboto-regular: 'Roboto',
  roboto-medium: 'Roboto',
  roboto-bold: 'Roboto-Bold',
  roboto-condensed-light: 'RobotoCondensed-Light',
  roboto-condensed-regular: 'RobotoCondensed-Regular',
  roboto-condensed-bold: 'RobotoCondensed-Bold'
);

$font-family-base: 'Roboto';
$font-family-ios-base: 'Roboto';
$font-family-md-base: 'Roboto';
$font-family-wp-base: 'Roboto';

/// Deprecated
$font-weight: (
  thin: 100,
  normal: 400,
  bold: 700
);

$header: (
  background: #d6d6d6
);
/// Deprecated

// General Overrides

// MOBILE
$outer-gutter-mobile: 12px;
// $inner-gutter-mobile: $outer-gutter-mobile / 2; // Deprecation Using / for division outside of calc() is deprecated and will be removed in Dart Sass 2.0.0.
$inner-gutter-mobile: $outer-gutter-mobile * 0.5;

$button-shadow-mobile: 0px 1px 5px 0px #cecece;
$item-shadow-mobile: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
$border-radius: 3px;
$card-border-radius: 8px;

// PC
$outer-gutter-pc: 24px;
// $inner-gutter-pc: $outer-gutter-pc / 2; // Deprecation Using / for division outside of calc() is deprecated and will be removed in Dart Sass 2.0.0.
$inner-gutter-pc: $outer-gutter-pc * 0.5;

$main-navbar-height: 60px;
$main-navbar-width-submenu: 72px;
// Amount para funciones darken(), lighten() de Sass
$on-event-amount: 5;

// ITEMS & CARDS
$padding-item-pc: 12px;

// custom shadows for PC version//
$button-shadow-pc: 0px 1px 6px 1px #cecece;
$icon-shadow-pc: 0px 0px 4px 0px rgba(0, 0, 0, 0.8);
$item-shadow-pc: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);

// custom borders for PC version//
$border-item-pc: 1px solid #dedede;
$border-radius-pc: 3px;

$toggle-md-track-background-color-on: map-get($sb-colors, primary);
$var: 1;

// General Overrides

// TICKET VARIABLES
$ticket-full-modal-height: calc(100% - 51px);
$one-single: 81px;
$two-betbuilder: 89px;
$three-betbuilder: 114px;
$footer: 146px;
$confirmed-header: 66px;
$one-single-selection: calc(#{$footer} + #{$one-single});
$two-simple-selection: calc(#{$footer} + (#{$one-single} * 2));
$one-simple-two-betbuilder: calc(#{$footer} + #{$one-single} + #{$two-betbuilder});
$one-simple-three-betbuilder: calc(#{$footer} + #{$one-single} + #{$three-betbuilder});
$two-betbuilder: calc(#{$footer} + #{$two-betbuilder});
$three-betbuilder: calc(#{$footer} + #{$three-betbuilder});

$height-app-header: 51px !default;
$height-ticket-header: 66px !default;
$height-ticket-filter: 44px !default;
$height-ticket-actions: 123px !default;
$height-ticket-select: 60px !default;
$height-ticket-confirmation: 129px !default;
$height-ticket-overask: 79px !default;
$height-ticket-keyboard: 191px !default;
