﻿//Partial for all forms related: input, select, textarea....

.subHeaderSelects {
  overflow: hidden;
  border-bottom: 1px solid #dadada;
  padding: 0;
  min-height: 2rem !important;
  background-color: #fff;
  height: 41px;

  ion-list {
    ion-col {
      padding: 0;
      height: 3rem;
      margin: 0.5rem;
      margin-left: 0;
      margin-right: 0;
    }

    ion-col:first-child {
      border-right: 1px solid rgba(129, 129, 129, 0.72);
    }

    .select-icon .select-icon-inner {
      top: 23% !important;
      width: 8px !important;
      height: 8px !important;
      left: -2px !important;
    }

    ion-label {
      flex: 1;
      @include font(roboto-condensed-bold, title, normal);
      color: get-sb-color(primary) !important;
      text-transform: uppercase;
    }

    ion-item {
      padding: 0 !important;
      border: 0 !important;
      margin-bottom: 0;
      margin-top: 0;
      height: 2.5rem;
    }

    .select-text {
      margin-left: 4px;
      // font-family: 'RobotoCondensed-Regular';
      font-family: $font-family-primary;
      font-weight: get-font-weight(regular);
      font-size: 1.45rem;
      line-height: 2.9rem;
    }

    .select-icon {
      position: relative;
      width: 12px;
      height: 29px;
      margin-top: 6px !important;
    }

    .input-wrapper ion-label,
    .input-wrapper ion-select {
      @include flex(inline-flex, flex-start, center, row, nowrap);
      align-self: center;
      margin: 0;
      padding: 0 !important;
    }

    .input-wrapper,
    .item-inner {
      height: 3rem;
      padding: 0 !important;
    }
  }
}

.input-prelabel {
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.user-input-container {
  padding: $outer-gutter-mobile;
  border-bottom: 1px solid #dedede;
}

.item-input {
  ion-input {
    /*margin: 0 !important;*/
  }

  &.input-has-focus .item-inner {
    border: 0 !important;
    box-shadow: none !important;
  }

  &.input-has-value:not(.input-has-focus) .item-inner {
    border-bottom: 0 !important;
    box-shadow: none !important;
  }

  &.input-has-value:not(.input-has-focus):last-child {
    border: 0;
    box-shadow: none !important;
  }
}

.item-radio {
  background: transparent;
}

.item-select {
  border-radius: 0;
  background-color: #fff;
  border-bottom: 3px solid #e6e6e6 !important;
  /*margin-bottom: 10px !important;*/
  &.input-has-focus {
    border: 0 !important;
  }
}

// Se comenta porque afecta a todos los ion-select y genera conflictos con estilos de Ionic
// ion-select {
//   padding: 8px 12px 0 !important;
//   height: 4.6rem;
// }

.select-icon-inner {
  position: absolute;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #8c8c8c !important;
  top: 50%;
  left: -4px !important;
  margin-top: 0px !important;
  width: 9px !important;
  height: 9px !important;
  border-left: none !important;
  border-top: none !important;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

ion-toggle {
  .toggle-checked {
    .toggle-inner {
      background: get-sb-color(grey-200) !important;
    }
  }
}

.selectInput {
  .select-text {
    top: 0 !important;
  }
}

.item-label-fixed .select-text {
  margin-top: 16px !important;
}

.item-checkbox {
  .item-inner {
    padding: 0 0 0 $inner-gutter-mobile !important;
  }
}

input,
.item-input,
ion-input {
  &:focus {
    outline: none !important;
  }
}

.platform-android {
  .text-input-md {
    margin-top: 3px !important;
    margin-bottom: 8px;
    margin-left: 0;
    width: calc(100% - 8px);
  }
}
/*CUSTOM INPUT*/

.cdr-item-input,
.cdr-item-input-disabled {
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid get-sb-color(grey-600);
  margin: 23px 0 0;
  margin-right: 0;
  margin-left: 0;
  font-size: 1.6rem;
  font-weight: 400;

  ion-select {
    height: 4.6rem !important;
    min-height: 4.6rem !important;
    --background: transparent;
  }

  ion-input {
    height: 4.6rem !important;
    min-height: 4.6rem !important;

    &[class*='md'] {
      .native-input {
        caret-color: black;
      }
    }
    &[class*='ios'] {
      .native-input {
        caret-color: black;
      }
    }
    .label-text {
      color: #999 !important;
    }
    &.ion-input-error {
      .label-text {
        color: #ff0000 !important;
      }
    }
    &.ion-input-error.has-focus {
      .label-text {
        color: #ff0000 !important;
      }
    }
    &.has-focus {
      caret-color: black !important;
      .label-text {
        font-size: 1.8rem;
        font-weight: 400;
        color: #79c000 !important;
        transform: translate3d(0, -0.6rem, 0);
      }
    }
    &.link-error {
      .label-text {
        color: #ff0000 !important;
      }
    }
    &.has-value {
      .label-text {
        font-size: 1.8rem;
      }
    }
    &.ion-input-no-transform-label.ion-input-error.has-focus {
      .label-text {
        transform: translate3d(0, -0.7rem, 0) !important;
      }
    }
    &.ion-input-no-transform-label.has-focus {
      .label-text {
        transform: translate3d(0, -0.7rem, 0) !important;
      }
    }
    &.ion-input-no-transform-label.has-value {
      .label-text {
        transform: translate3d(0, -0.7rem, 0) !important;
      }
    }
  }
}

.passwordRecovery {
  ion-input {
    .label-text {
      color: #000 !important;
    }

    &.has-focus .label-text {
      color: #000 !important;
    }
  }
}

.cdr-item-input-disabled {
  background-color: #f5f5f5;
}
.cdr-item-input-disabled::part(native) {
  background-color: transparent !important;
  height: 4.6rem !important;
  min-height: 4.6rem !important;
}
.cdr-item-input::part(native) {
  height: 4.6rem !important;
  min-height: 4.6rem !important;
}

/* END CUSTOM INPUT*/

.button {
  &-md {
    color: #fff;
    background-color: #79c000;
    margin: 0.4rem 0.2rem;
    padding: 0 1.1em;

    &:hover {
      background-color: #69a700;
    }
  }

  &-full {
    width: 100%;
    display: block;
    contain: strict;
  }
}
/*CUSTOM INPUT FLOATING*/
.cdr-item-input-floating {
  max-height: 3rem;
  border: 1px solid get-sb-color(grey-600);

  &.error {
    .cdr-label-floating {
      color: get-sb-color(red-regular);
    }
  }

  .cdr-label-floating {
    pointer-events: none !important;
    margin: 0 !important;
    padding: 1rem 0;
    line-height: 0.2;
    transform: translate3d(0, 1.25rem, 0);
  }

  .cdr-input-floating {
    margin-top: 0;
    font-size: 1.6rem;

    > .text-input {
      margin-bottom: 0 !important;
    }
  }
}

.toggle-ios.toggle-checked .toggle-icon {
  background: get-color(primary) !important;
}

.cdr-header-toggle {
  background: transparent;
  padding: 0;
  font-size: 1.1rem;
  color: #fff;
  width: auto;
  min-height: 3.3rem;
  &.no-background {
    &.toggle-checked {
      background: get-color(primary) !important;
    }

    .toggle-inner {
      border: 1px solid get-color(grey);
    }

    .toggle-icon {
      background: get-color(grey);
    }
  }

  &.input-has-focus {
    border: 0 !important;
    border-color: transparent !important;
  }

  > .item-inner {
    padding: 0 !important;
    border: 0 !important;
  }

  ion-label {
    margin-right: 0;
    font-size: 1.2rem;
  }

  ion-toggle {
    padding-right: 0 !important;
    @include flex(flex, flex-start, center, row, nowrap);
  }

  .toggle-ios {
    > .toggle-icon {
      width: 40px;
      height: 20px;

      &.toggle-checked {
        .toggle-inner {
          transform: translate3d(20px, 0, 0);
        }
      }

      .toggle-inner {
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: get-color(grey);
        box-shadow: none;
      }
    }
  }
}

.toggle-md {
  contain: none !important;
}

.input-has-focus {
  border: 0 !important;
}

.platform-core {
  .item-input {
    ion-input {
      margin: 0 !important;
    }
  }

  .item.item-md .checkbox-md {
    margin-right: 6px !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .myWrapInput {
    .item-select {
      margin-bottom: 0px !important;

      ion-select {
        .select-text {
          width: 92% !important;
          font-size: 1.7rem;
          line-height: 3rem !important;
        }
      }
    }
  }

  .item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus) .item-inner {
    box-shadow: none !important;
  }

  .subHeaderSelects {
    overflow: hidden;
    border-bottom: 1px solid #dadada;
    padding: 0;
    min-height: 2rem !important;
    background-color: #fff;
    height: 41px;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    .select-icon {
      width: 24px;
      height: 22px;
      overflow: hidden;
      margin-top: 12px !important;
      transform: rotate(90deg);
    }

    .select-icon-inner {
      background-color: #000000;
      border-color: #000000 !important;
    }

    ion-list {
      margin: 0;
    }

    ion-col {
      padding: 0;
      height: 3rem;
      margin: 0.5rem;
      margin-left: 0;
      margin-right: 0;
    }

    ion-col:first-child {
      border-right: 1px solid rgba(129, 129, 129, 0.72);
    }

    .select-icon .select-icon-inner {
      top: -3px;
    }

    ion-label {
      flex: none;
      color: #00a23f;
      font-size: 1.4rem;
      // font-family: 'RobotoCondensed-Bold';
      font-family: $font-family-primary;
      font-weight: get-font-weight(bold);
      text-transform: uppercase;
    }

    ion-item {
      border: 0 !important;
      margin-bottom: 0;
      margin-top: 0;
      height: 2.5rem;
      max-width: 18vw;
      margin: 0 auto;
    }

    .select-text {
      margin-left: 4px;
      // font-family: 'RobotoCondensed-Regular';
      font-family: $font-family-primary;
      font-weight: get-font-weight(regular);
      font-size: 1.45rem;
      line-height: 2.9rem;
    }

    .input-wrapper ion-label,
    .input-wrapper ion-select {
      margin: 0;
      padding: 0;
    }

    .input-wrapper,
    .item-inner {
      height: 3rem;
    }
  }
  /**SELECT FOR IE ONLY*/
  .select-explorer {
    width: 100%;
    height: 4.4rem;
    padding: 6px;
    @include border('all', 1px, solid, grey-600);
    border-radius: $border-radius;
    @include font(roboto-regular, title, normal);
    color: #616161;
  }
  /**SELECT FOR IE ONLY*/
  .select-explorer-container {
    width: 100%;
    display: inline-block;
    padding: 6px;

    .label-explorer {
      max-width: 10%;
      @include font(roboto-bold, title, bold);
      color: get-sb-color(primary);
    }

    .subheader-select-explorer {
      max-width: 90%;
      min-width: 84%;
      border: 0;
      border-radius: 0;
      // font-family: 'RobotoCondensed-Regular';
      font-family: $font-family-primary;
      font-weight: get-font-weight(regular);
      font-size: 1.45rem;
    }
  }
}

.platform-mobile {
  // Styles only for personal-info-page mobile
  personal-info-page {
    ion-input {
      .label-text {
        color: black !important;
        font-weight: bold;
      }
    }
    .cdr-item-input-disabled {
      * {
        font-weight: bold !important;
      }
    }
    .ion-input-no-transform-label {
      --color: #000;
      opacity: 0.7;

      .label-text-wrapper {
        min-height: 20px;
        height: 20px;
      }

      .label-text {
        font-size: 1.6rem !important;
        color: #000 !important;
        font-weight: 500;
        margin-bottom: 3px;
        font-size: 1.6rem;
      }

      &.ios {
        opacity: 1;

        .label-text {
          font-size: 1.8rem !important;
        }

        .native-input {
          font-size: 1.7rem;
        }
      }
    }
  }
}
.backgroundheader {
  .button-md {
    background-color: get-sb-color(newprimary);
  }
  .select-explorer-container {
    .label-explorer {
      color: transparent;
    }
  }
}
