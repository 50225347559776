/*
 * ION BUTTONS OVERRIDES
*/
ion-button.sportsbook {
  --box-shadow: none;
  --background-hover: transparent;
  --ripple-color: transparent;
  --border-radius: 5px;
  margin: 0;
  max-height: 44px;
  min-height: 44px;

  &.btn-auto-height {
    min-height: auto;
  }

  ion-col.sportsbook & {
    max-width: 100%;
    min-width: 100%;
  }

  ion-label {
    letter-spacing: 0;
    max-width: 100%;
    text-wrap: wrap;
    &.text-small, .text-small {
      font-size: 12px !important;
    }
  }

  &.btn-transparent {
    --background: transparent;
    --background-focused: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0.9;
    --color: #{get-sb-color(light)};
  }
  &.btn-transparent.icon-only {
    --background: transparent;
    --background-focused: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0.9;
    min-width: auto !important;
    min-height: auto !important;
    height: auto;
    width: auto;
    --color: #{get-sb-color(light)};
    &::part(native) {
      padding: 0;
    }
  }
  &.btn-transparent.with-border {
    --background: transparent !important;
    --background-focused: transparent !important;
    --background-activated: transparent !important;
    --background-activated-opacity: 0.9;
    --color: #{get-sb-color(light)};
    --border-color: #{get-sb-color(light)};
    --border-width: 1px;
    --border-style: solid;
  }
  &.btn-primary {
    --background: #{get-sb-color(primary)};
    --background-focused: #{get-sb-color(primary)};
    --background-activated: #{get-sb-color(primary)};
    --background-activated-opacity: 0.9;
    --color: #{get-sb-color(light)};
  }
  &.btn-secondary {
    --background: #{get-sb-color(secondary)};
    --background-focused: #{get-sb-color(secondary)};
    --background-activated: #{get-sb-color(secondary)};
    --background-activated-opacity: 0.9;
    --color: #{get-sb-color(light)};
  }
  &.btn-light {
    --background: #{get-sb-color(light)};
    --background-focused: #{get-sb-color(light)};
    --background-activated: #{get-sb-color(light)};
    --background-activated-opacity: 0.9;
    --color: #{get-sb-color(grey-900)};
  }
  &.btn-negative {
    --background: #{get-sb-color(grey-100)};
    --border-color: #{get-sb-color(primary)};
    --border-style: solid;
    --border-width: 1px;
    --color: #{get-sb-color(primary)};
    &.disabled {
      cursor: not-allowed;
    }
  }
  &.btn-danger {
    --background: transparent;
    --color: #{get-sb-color(orange)};
    &.disabled {
      cursor: not-allowed;
    }
  }
  &.btn-wide {
    min-height: 25px !important;
    max-height: 25px !important;
  }

  ion-label {
    &.text-uppercase {
      text-transform: uppercase;
    }
    &.font-weight-bolder {
      font-weight: get-font-weight(bold);
    }
  }

  .codere-icon {
    &.icon-right {
      margin-right: 5px;
    }
    &.icon-left {
      margin-left: 5px;
    }
  }
}

/*
 * ION ITEM OVERRIDES
 */
ion-item.sportsbook p {
  color: #666666;
}

/*
 * ION INPUT OVERRIDES
 */
ion-toolbar.sportsbook {
  --background: transparent;
}

/*
  * ION CHIP OVERRIDES
  */
ion-chip.sportsbook {
  --background: #{get-sb-color(primary)};
  --color: #{get-sb-color(light)};
}

/**
  * ION SEGMENT OVERRIDES
  */
ion-segment.sportsbook {
  --background-hover: transparent;
  --ripple-color: transparent;
}
ion-segment-button.sportsbook {
  --background-hover: transparent;
  --ripple-color: transparent;
}