@import 'src/app/modules/shared/assets/global/styles/base/index';
@import 'src/app/modules/shared/assets/global/styles/global';

@import 'src/app/modules/shared/assets/global/styles/typography/index';
@import 'src/app/modules/shared/assets/global/styles/atoms/index';
@import 'src/app/modules/shared/assets/global/styles/patterns/index';
@import 'src/app/modules/shared/assets/global/styles/modifiers/index';
@import 'src/app/modules/shared/assets/global/styles/animations/index';
@import 'src/app/modules/shared/assets/global/styles/fallbacks/index';
@import 'src/app/modules/shared/assets/global/styles/fallbacks/fallbacks';

@import 'src/app/modules/shared/assets/global/styles/reset';
@import 'src/app/modules/shared/assets/global/styles/forms';
@import 'src/app/modules/shared/assets/global/styles/reset-ionic';
@import 'src/app/modules/shared/assets/global/styles/buttons';
@import 'src/app/modules/shared/assets/global/styles/items';
@import 'src/app/modules/shared/assets/global/styles/alerts';
@import 'src/app/modules/shared/assets/global/styles/lists';

@import 'src/app/modules/shared/assets/global/styles/pc-core';
@import 'src/app/modules/shared/assets/global/styles/mobile-core';
@import 'src/app/modules/shared/assets/global/styles/modals';

@import 'src/app/modules/shared/assets/global/styles/ticket';

/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-size: $font-size-root;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-webkit-text-size-adjust: none;
  -ms-webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// #region scrollbar styling

// Apply styles only on desktop
ion-app.platform-core {
  // Scrollbar style for base page
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border: 1px solid transparent;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
    border-left: none;
    margin-right: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  // Scrollbar style for Ionic scrollable content
  ion-content::part(scroll) {
    // This will hide the scroll given by ionic's <main class='scroll-y'/>
    position: unset !important;
    width: 100%;
  }

  ion-content {
    --overflow: auto;
    overflow: auto;
    --offset-bottom: auto !important;

    .inner-scroll {
      position: unset !important;
      display: contents !important;
    }

    &::-webkit-scrollbar {
      width: 7px !important;
      height: 7px !important;
      border: 1px solid transparent !important;
      border-radius: 3px !important;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.2) !important;
      border-left: none !important;
      margin-right: 2px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.4) !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.6) !important;
    }
  }
}

// #endregion scrollbar styling
