.modalCashier {
  --width: 800px;
  --height: calc(100vh - 10%);
  --backdrop-opacity: 0.75;
  --ion-backdrop-opacity: 0.75;

  .modal-wrapper {
    opacity: 1;
    transform: translateY(0px);
    width: 100%;
    height: 100%;

    .veridas-container {
      width: 100%;
      height: 100%;

      #veridasTarget {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.cs-request-info {
  --backdrop-opacity: 0.75 !important;
}

.cs-item-selector-modal {
  --height: 500px;
  border-radius: 5px;
}

@media screen and (min-height: 938px) {
  .modalCashier {
    --height: 844px;
  }
}

.modal-tycSelfie {
  --height: 300px;
  --width: 300px;
  --backdrop-opacity: 0.4;
}

.cs-verification-alert-modal {
  --width: 350px !important;
  --height: 350px !important;
  --backdrop-opacity: 0.75 !important;
}

ion-modal.cs-verification-alert-modal {
  --ion-background-color: white;
}

ion-popover.modal-default {
  --backdrop-opacity: 0;
  --background: transparent;
  --width: 250px;
  --max-height: 90%;
  --offset-x: 0px;
}
.modal-default::part(content) {
  top: 60px;
  // Estos cambios se metieron para modal login y no deberian de estar aqui, afectan a todos los modales
  // --background: transparent;
  --box-shadow: none;
}
.platform-mobile {
  ion-popover.modal-default {
    --max-width: 340px;
  }
  .modal-default::part(content) {
    top: calc(6%);
  }
}
ion-popover.modal-prp {
  --backdrop-opacity: 0;
  --background: transparent;
  --width: 100%;
  --max-width: 500px;
  --offset-x: 0px;
}
.modal-prp::part(content) {
  top: 60px;
}
.platform-mobile {
  ion-popover.modal-prp {
    --max-width: 340px;
  }
  .modal-prp::part(content) {
    top: calc(6%);
  }
}

ion-modal.is-modal {
  --background: transparent !important;
  --box-shadow: none !important;
}
.platform-mobile {
  ion-modal.is-modal {
    --ion-backdrop-opacity: 0.4 !important;
    --backdrop-opacity: 0.4 !important;
  }
}

ion-modal {
  --background: var(--ion-background-color, transparent) !important;
}

.platform-core {
  ion-modal.modalh800 {
    --width: 600px;
    --height: 95vh !important;
    &.modalcookieconsent {
      --background: transparent;
      --backdrop-opacity: 0.75 !important;
    }
  }
  .modalh800::part(content) {
    top: 2rem !important;
  }

  //styles for casino calendar
  .modalCalendar::part(content) {
    height: 90vh;
    max-height: 850px;
    width: 800px;
  }

  .modalCalendar::part(backdrop) {
    --backdrop-opacity: 0.75 !important;
  }

  .limitsFormulModal::part(backdrop) {
    --backdrop-opacity: 0.75 !important;
  }

  .modalMoreFilters::part(content) {
    width: auto;
  }

  .modalMoreFilters::part(backdrop) {
    --backdrop-opacity: 0.75 !important;
  }
}

ion-modal#ticket-small-footer--modal::part(backdrop) {
  --height: auto !important;
  --background: red;
  --max-height: 80px;
}

ion-modal#ticket-small-footer--modal {
  --height: auto !important;
  --background: red;
  --max-height: 80px;
}

ion-modal.modalh800 {
  --ion-backdrop-opacity: 0.75;
}
ion-alert.alert-cookies-default {
  --max-width: calc(100% - 24px);
  --border-radius: 5px;

  .alert-message {
    text-align: left;
  }

  .alert-wrapper {
    border-radius: 5px;
  }
  &.newRebrandingCss {
    button {
      border-radius: 3px;
    }
  }
  .alert-button-group.sc-ion-alert-md {
    justify-content: center;
  }
  .alert-button.sc-ion-alert-md {
    -webkit-margin-end: 0;
    margin-inline-end: 0;
  }
  .alert-button-group {
    button {
      padding-left: 50px;
      padding-right: 50px;
      font-size: 2rem;
      font-weight: 500;
    }
  }
}
ion-modal.alertCtaPromo {
  --ion-backdrop-opacity: 0;
  --height: auto;
  --width: auto;
}

.platform-core {
  ion-alert.alert-cookies-default {
    .alert-wrapper {
      border-radius: 2px;
    }
  }

  ion-modal.slots-config {
    --height: auto;

    .newSlots {
      display: block;
      contain: content;
    }
  }
}
.platform-core {
  ion-modal.modal-welcomebonus {
    --backdrop-opacity: 0.8 !important;
  }
}
