@import 'src/app/modules/shared/assets/global/styles/base/index';

/// Position

.p-relative {
  position: relative;
}

/// Position

/// Display

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-self-center {
  align-self: center;
}

/// Display

/// Margin

.m-bottom-0 {
  margin-bottom: 0;
}

.m-left-1 {
  margin-left: 1rem;
}

.m-h-1 {
  margin: 0 1rem;
}
/// Margin

/// Grid

.is-narrow-content {
  max-width: 1720px;
  width: 95%;
  margin: 0 auto !important;
}

.is-wrap {
  flex-flow: row wrap;
}

.is-nowrap {
  flex-flow: row nowrap;
}

.margin-grid-item-container {
  margin-bottom: $margin--sb-grid-item;
}

.cuota-especial-100 {
  max-width: 100% !important;
}

.cuota-especial-25 {
  max-width: calc(25% - 3px) !important;
}

// Bets

.sb-grid-container {
  min-height: calc(100vh - #{$height--sb-navbar + 5.1rem});
}

.sb-grid-item--bets-group {
  width: 100%;
}

.sb-grid-item--bets-group.has-2-groups {
  width: $width--sb-grid-has-2-groups;
}

.sb-grid-item--bets-group.has-3-groups {
  width: $width--sb-grid-has-3-groups;
}

// Buttons
.has-two-buttons sb-button {
  flex-basis: calc(50% - #{$margin--sb-grid-button});
  //flex: 0% 1 calc(50% - #{$margin--sb-grid-button});
  //min-width: calc(50% - #{$margin--sb-grid-button});
  max-width: calc(50% - #{$margin--sb-grid-button});
}

.has-three-buttons sb-button {
  //flex: 1;
  //flex: 0 1 calc(#{$one-third} - #{$margin--sb-grid-button});
  flex-basis: calc(#{$one-third} - #{$margin--sb-grid-button});
  //min-width: calc(#{$one-third} - #{$margin--sb-grid-button});
  max-width: calc(#{$one-third} - #{$margin--sb-grid-button});
}

.has-four-buttons sb-button {
  //flex: 1;
  //flex: 0 1 calc(25% - #{$margin--sb-grid-button});
  flex-basis: calc(25% - #{$margin--sb-grid-button});
  //min-width: calc(25% - #{$margin--sb-grid-button});
  max-width: calc(25% - #{$margin--sb-grid-button});
}

// .platform-core {

// .has-two-buttons .sb-button {
//     flex-basis: calc((100% - (#{$margin--sb-grid-button} * 2)) / 2);
//     max-width: calc((100% - (#{$margin--sb-grid-button} * 2)) / 2);
// }

// .has-three-buttons .sb-button {
//     flex-basis: calc((100% - (#{$margin--sb-grid-button} * 3)) / 3);
//     max-width: calc((100% - (#{$margin--sb-grid-button} * 3)) / 3);
// }

// .has-four-buttons .sb-button {
//     flex-basis: calc((100% - (#{$margin--sb-grid-button} * 4)) / 4);
//     max-width: calc((100% - (#{$margin--sb-grid-button} * 4)) / 4);
// }

// .has-two-buttons .sb-button {
//     flex-basis: calc(50% - #{$margin--sb-grid-button});
//     max-width: calc(50% - #{$margin--sb-grid-button});
// }

// .has-three-buttons .sb-button {
//     flex-basis: calc(#{$one-third} - #{$margin--sb-grid-button});
//     max-width: calc(#{$one-third} - #{$margin--sb-grid-button});
// }

// .has-four-buttons .sb-button {
//     flex-basis: calc(25% - #{$margin--sb-grid-button});
//     max-width: calc(25% - #{$margin--sb-grid-button});
// }

// }

/// Grid

/// Sizing

.w-100 {
  width: 100%;
}

/// Sizing

/// Fonts

@each $key, $value in $font-weight-map {
  .font-weight-#{$key} {
    font-weight: $value;
  }
}

/// Fonts

/// Text

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-capitalized {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-ellipsis {
  @include sb-text-ellipsis;
}

.line-through {
  text-decoration: line-through;
}

/// Text

/// Colors

@each $key, $value in $sb-modifiers-colors {
  .color-#{$key} {
    color: $value;
  }
}

@each $key, $value in $sb-modifiers-backgrounds {
  .background-color-#{$key} {
    background-color: $value;
    --background: $value;
  }
}

@each $key, $value in $sb-modifiers-borders {
  .border-color-#{$key} {
    border-color: $value;
  }
}

.background-transparent {
  background: transparent;
}

.is-locked {
  color: get-sb-color(text-muted);
  background: get-sb-color(background-muted);
}

/// Colors

/// Margin

@each $key, $value in $margin-map {
  .m-#{$key} {
    margin: $value;
  }

  .mx-#{$key} {
    margin-left: $value;
    margin-right: $value;
  }

  .my-#{$key} {
    margin-top: $value;
    margin-bottom: $value;
  }

  .p-#{$key} {
    padding: $value;
  }

  .px-#{$key} {
    padding-left: $value;
    padding-right: $value;
  }

  .py-#{$key} {
    padding-top: $value;
    padding-bottom: $value;
  }

  $directions: top right bottom left;

  @each $direction in $directions {
    .p-#{$direction}-#{$key} {
      padding-#{$direction}: $value;
    }

    .m-#{$direction}-#{$key} {
      margin-#{$direction}: $value;
    }
  }
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-bottom-0 {
  margin-bottom: 0;
}

.m-unset {
  margin: unset;
}

/// Margin

/// Padding

.has-no-padding {
  padding: 0;
}

/// Padding

/// Sizing
.w-50 {
  width: 50%;
}

.w-50 {
  width: 50%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

/// Sizing

/// Opacity

.opacity-02 {
  opacity: 0.2;
}

/// Opacity

.pointer-events-none {
  pointer-events: none;
}

/// TRANSFORM ///

/// Rotate
@each $degree in $degrees-map {
  .rotate-#{$degree} {
    transform: rotate($degree);
  }
}
/// Rotate

/// TRANSFORM ///

/// UI Elements

.sb-scoreboard-dot {
  display: block;
  min-width: 0.6rem;
  min-height: 0.6rem;
  @include border-radius(xl);

  &.is-active {
    background: get-sb-color(primary);
  }
}

//!NOT included
.sb-grid-header--desktop {
  @include flex-row($y-axis: center);
  height: 4.4rem;
}

.sb-grid-content {
  padding: 0 0.1rem 0.1rem;
}

.sb-sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: get-z-index(xl);
  display: block;
  top: 0;
  width: 100%;
  background-color: #{get-sb-color(grey-500)};

  &-border-bottom {
    border-bottom: 1px solid get-sb-color(grey-500);
  }
  &-widget {
    top: 75px;
  }
}

.sb-fixed {
  position: fixed;
  z-index: get-z-index(xl);
  display: block;
  top: 0;
  width: 100%;

  &-border-bottom {
    border-bottom: 1px solid get-sb-color(grey-500);
  }
  &-widget {
    top: 75px;
  }
}

.has-yellow-card {
  display: block;
  width: 0.8rem;
  height: 1.2rem;
  background: get-sb-color(yellow-regular);
  @include border-radius(xs);
}

.has-red-card {
  display: block;
  width: 0.8rem;
  height: 1.2rem;
  background: get-sb-color(red-regular);
  @include border-radius(xs);
}

/// UI Elements
