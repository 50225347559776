/// Sportbook SCSS Variables ///
@import './functions';
@import './variables';
/// Breakpoints
$breakpoints: (
  xs: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  grid-layout: 1050px,
  live-scoreboard: 380px,
  ticket-toggle: 413px,
  ticket-toggle-title: 374px,
  ticket-desktop: 1600px,
  mybets-desktop: 1300px
) !default;

$breakpoint--xs: 360px;
$breakpoint--sm: 576px;
$breakpoint--md: 768px;
$breakpoint--lg: 992px;
$breakpoint--xl: 1200px;

/// Breakpoints

/// Color

$sb-filter-modes: 'grey', 'dark', '2024';

/// Old map

/// primary: #79c000,

/// Old map

/// Sportbook's greys  /// NEW

/// 300, 500 & 900 from Shai
/// grey-100: #F3F3F3, /// No tocar
/// grey-200: #E8E8E8, /// No tocar
/// grey-300: #E0E0E0, /// By Shai /// No tocar
/// grey-400: #D3D3D3,
/// grey-500: #ADB2B5, /// By Shai /// No tocar
/// grey-600: #999FA3, /// 30% by Shai
/// grey-700: #7F868B, /// 50% by Shai
/// grey-800: #5C656D,
/// grey-900: #333F48  /// 100% Codere's grey /// By Shai /// No tocar /// All texts has to be this color

/// Sportbook's greys  /// NEW

/// Sportbook's colors /// NEW

/// primary:                  #79C000,
/// secondary:                #0056FE,
/// accent:                   #0056FE,
/// light:                    #FFFFFF,
/// white:                    #FFFFFF,
/// black:                     #000000,
/// text-dark:                map-get($sb-grey-map, grey-900),
/// text-light:               #FFFFFF,
/// text-muted:               map-get($sb-grey-map, grey-700),
/// item-grey:                map-get($sb-grey-map, grey-100),
/// border-dark:              map-get($sb-grey-map, grey-900),
/// border-regular:           map-get($sb-grey-map, grey-500),
/// border-light:             map-get($sb-grey-map, grey-800),
/// border-button:            map-get($sb-grey-map, grey-300),
/// border:                   map-get($sb-grey-map, grey-400),
/// background-badge:         #000000,
/// background-dark:          map-get($sb-grey-map, grey-900),
/// background-regular:       map-get($sb-grey-map, grey-400),
/// background-light:         map-get($sb-grey-map, grey-300),
/// background-base:          map-get($sb-grey-map, grey-500),
/// background-button:        #FFFFFF,
/// background-muted:         map-get($sb-grey-map, grey-200),
/// background-sets:          rgba(black, 0.6),
/// background-sets-columns:  rgba(black, 0.25),
/// background-scoreboards:   #669900,
/// background:               map-get($sb-grey-map, grey-500),
/// lobby-navbar-desktop:     #3d4952,
/// button-hover:             #B5D979,
/// green-dark:               #037500,
/// red-regular:              #FF0000,
/// red-dark:                 #ED1C24,
/// yellow-regular:           #FFF600,
/// transparent:              transparent

/// Sportbook's colors /// NEW

/// Maps just for modifiers /// NEW

/// $sb-modifiers-colors:(
/// primary: map-get($sb-colors, primary),
/// secondary: map-get($sb-colors, secondary),
/// accent: map-get($sb-colors, accent),
/// yellow: map-get($sb-colors, yellow-regular),
/// dark: map-get($sb-colors, grey-900),
/// muted: map-get($sb-colors, grey-700),
/// light: map-get($sb-colors, light),
/// grey-100: map-get($sb-colors, grey-100),
/// grey-200: map-get($sb-colors, grey-200),
/// grey-300: map-get($sb-colors, grey-300),
/// grey-400: map-get($sb-colors, grey-400),
/// grey-500: map-get($sb-colors, grey-500),
/// grey-600: map-get($sb-colors, grey-600),
/// grey-700: map-get($sb-colors, grey-700),
/// grey-800: map-get($sb-colors, grey-800),
/// grey-900: map-get($sb-colors, grey-900),
/// score: map-get($sb-colors, grey-500),
/// alert: map-get($sb-colors, red-regular),
/// progress: map-get($sb-colors, orange),
/// lost: map-get($sb-colors, black),

/// $sb-modifiers-backgrounds:(
/// primary:   map-get($sb-colors, primary),
/// secondary: map-get($sb-colors, secondary),
/// yellow:     map-get($sb-colors, yellow-regular),
/// white:     map-get($sb-colors, light),
/// base:      map-get($sb-colors, grey-500),
/// light:     map-get($sb-colors, grey-300),
/// regular:   map-get($sb-colors, grey-400),
/// dark:      map-get($sb-colors, grey-900),
/// grey-800: map-get($sb-grey-map, grey-800),
/// button:    map-get($sb-colors, grey-100),
/// muted:     map-get($sb-colors, grey-200),
/// transparent: map-get($sb-colors, transparent),

/// $sb-modifiers-borders:(
/// primary:   map-get($sb-colors, primary),
/// secondary: map-get($sb-colors, secondary),
/// white:     map-get($sb-colors, light),
/// light:     map-get($sb-colors, grey-800),
/// regular:   map-get($sb-colors, grey-500),
/// dark:      map-get($sb-colors, grey-900),
/// grey-200: map-get($sb-colors, grey-200),
/// button: map-get($sb-colors, grey-300),
/// white: map-get($sb-colors, light),
/// Maps just for modifiers /// NEW

/// Color

/// Font

/// Family

$font-family-primary: 'Roboto' !default;
$font-family-secondary: 'Codere-Icons' !default;
$font-family-tertiary: 'RobotoCondensed-Regular' !default;
$font-family-ionic: 'Ionicons' !default;

/// Family

/// Weight

$font-weight-map: (
  light: 300,
  regular: 400,
  medium: 600,
  bold: 700
);

/// Weight

// Size

$sb-font-size-base: 14px !default;

$font-size-map-mobile: (
  sm: 1.1rem,
  md: 1.2rem,
  lg: 1.3rem,
  xl: 1.4rem,
  15: 1.5rem,
  16: 1.6rem,
  17: 1.7rem,
  18: 1.8rem,
  19: 1.9rem
) !default;

$font-size-map-desktop: (
  sm: 0.9rem,
  md: 1rem,
  lg: 1.1rem,
  xl: 1.2rem,
  xxl: 1.4rem,
  15: 1.5rem,
  16: 1.6rem,
  17: 1.7rem,
  18: 1.8rem,
  19: 1.9rem
) !default;

// Size

$sb-font-size-base: 14px !default;

$font-size-map-mobile: (
  sm: 1.1rem,
  md: 1.2rem,
  lg: 1.3rem,
  xl: 1.4rem,
  15: 1.5rem,
  16: 1.6rem,
  17: 1.7rem,
  18: 1.8rem,
  19: 1.9rem
) !default;

$font-size-map-desktop: (
  sm: 0.9rem,
  md: 1rem,
  lg: 1.1rem,
  xl: 1.2rem
) !default;

/// Size

/// Line Height

$line-height-map: (
  xs: 1,
  sm: 1.1,
  md: 1.2,
  lg: 1.3,
  xl: 1.4
) !default;

/// Line Height

/// Font

/// Text

$sb-letter-spacing--sm: -0.2px !default;
$sb-line-height-correction: 0.1rem !default;

/// Text

/// Z-Index

$z-index-xs: -1 !default;
$z-index-sm: 1 !default;
$z-index-md: 2 !default;
$z-index-lg: 3 !default;
$z-index-xl: 4 !default;

$z-index-map: (
  xs: $z-index-xs,
  sm: $z-index-sm,
  md: $z-index-md,
  lg: $z-index-lg,
  xl: $z-index-xl
);

/// Z-Index

/// Margin

$margin--sm: 1px !default;
$margin--md: 2px !default;
$margin--lg: 3px !default;

$margin--base: 1rem !default;

$margin--sb-dropdown-desktop: 1.5px !default;
$margin--sb-grid-item: 1px !default;
$margin--sb-grid-bets-group: 4px !default;
$margin--sb-grid-button: 3px !default;

// $one-third: 100% / 3 !default; // Deprecation Using / for division is deprecated and will be removed in Dart Sass 2.0.0.
$one-third: 33.33333%;

$margin-map: (
  0: 0,
  1: 1rem,
  2: 2rem,
  3: 3rem,
  4: 4rem,
  5: 5rem
) !default;

/// Margin

/// Border

$border-width--sm: 1px !default;
$border-width--md: 2px !default;
$border-width--lg: 3px !default;
$border-width--xl: 4px !default;

$border-color--sb-back-button: map-get($sb-colors, border-light);

/// Border

/// Border radius

$border-radius-map: (
  xs: 1px,
  sm: 2px,
  md: 3px,
  lg: 4px,
  xl: 50px,
  circle: 50%,
  rounded: 5px
) !default;

/// Border radius

/// TRANSFORM ///

/// Rotate
$degrees-map: (0, 45, 90, 180) !default;
/// Rotate

/// TRANSFORM ///

/// Transitions

$transition-timing--xs: 0.1s !default;
$transition-timing--sm: 0.15s !default;
$transition-timing--md: 0.2s !default;
$transition-timing--lg: 0.25s !default;
$transition-timing--xl: 0.3s !default;

$transition--sb-dropdown-expanded: all $transition-timing--sm ease-in !default;
$transition--sb-rotate-180: all $transition-timing--sm ease-in !default;
$transition--sb-ticket: all $transition-timing--sm ease !default;
$transition--sb-translate: all $transition-timing--lg ease-in-out !default;
$transition--sb-hover: all $transition-timing--lg ease-in-out !default;

/// Transitions

/// Navbar

$height--sb-navbar: 7.5rem !default;
$height--sb-navbar-pc: 4.4rem !default;
$height--sb-navbar-item-divider: 4.4rem !default;

/// Navbar

/// Scoreboard

$width--scoreboard-badge: 8.6rem !default;
$width--scoreboard-column-baseball: 2rem !default;
$width--scoreboard-column-sets: 2.6rem !default;

/// Scoreboard

/// Dropdown

$height--sb-grid-filter: 4.4rem !default;
$width--sb-dropdown-thumbnail: 2.4rem !default;

/// Dropdown

/// Grid

/// Grid filter
$height--sb-grid-filter: 4.4rem !default;
$height--codere-grid-filter: 4.4rem !default;

/// Grid blocks width
$width--sb-grid-thumbnail: 3rem !default;

/// Bets groups
$width--sb-grid-has-2-groups: calc((100% / 2) - #{$margin--sb-grid-bets-group}) !default;
$width--sb-grid-has-3-groups: calc((100% / 3) - #{$margin--sb-grid-bets-group}) !default;

/// Scoreboards
$height--video-streaming-mobile: 56.4vw !default;
$height--video-streaming-desktop: 10vw !default;
$height--video-streaming-races-desktop: 32.4vw !default;
$width--sb-scoreboard-detail: calc((100% - (#{$margin--sb-grid-button} * 3)) / 4) !default;

$sb-scoreboards: (
  'american_football',
  'badminton',
  'baseball',
  'basketball',
  'ebasket',
  'beach_soccer',
  'beach_volleyball',
  'billard',
  'boxing',
  'darts',
  'esports',
  'golf',
  'handball',
  'ice_hockey',
  'motor',
  'rugby',
  'snooker',
  'soccer',
  'efootball',
  'futsal',
  'tennis',
  'table_tennis',
  'tennis_clay',
  'tennis_grass',
  'tennis_indoor',
  'volleyball'
) !default;

/// Scoreboards

/// Betslip

$box-shadow--betslip: 0px -2px 8px 0px rgba(0, 0, 0, 0.5) !default;
$box-shadow--ticket-up: 0px -2px 8px 0px rgba(0, 0, 0, 0.5) !default;
$box-shadow--ticket-down: 0px 2px 8px 0px rgba(0, 0, 0, 0.5) !default;
