﻿.sb-alert {
  ion-backdrop {
    background-color: get-sb-color(background-dark);
    opacity: 0.8 !important;
  }

  .alert-wrapper {
    margin: 0;
    padding: 2.4rem;
    border-radius: get-border-radius(lg) !important;
    box-shadow: none;
    opacity: 1;
  }

  .alert-head,
  .alert-message {
    padding: 0 0 1rem 0;
    overflow-y: auto;
  }

  .alert-radio-group {
    border: none;

    .alert-radio-icon {
      order: unset;
      position: relative;
      display: block;
      left: unset;
      top: unset;
      width: 2.6rem;
      min-width: 2.6rem;
      max-width: 2.6rem;
      height: 2.6rem;
      border-width: $border-width--md;
      border-style: solid;
      border-color: get-sb-color(border-dark);
      border-radius: get-border-radius(circle);

      .alert-radio-inner {
        top: 5px;
        bottom: 5px;
        right: 5px;
        left: 5px;
        width: auto;
        height: auto;
        white-space: normal;
        background-color: get-sb-color(text-dark);
        border: none;
        border-radius: get-border-radius(circle);
      }
    }

    .alert-radio-label {
      margin-bottom: 0;
      padding: 1.2rem 2rem;
      @extend .sb-ticket--modal-subtitle !optional;
      color: get-sb-color(text-dark);
    }
  }
 
  .alert-button-group {
    justify-content: space-between;
    padding: 1.8rem 0 0 0;
    padding-top: 5%;

    .alert-button {
      margin-right: 1rem;
      border: $border-width--sm solid #{get-sb-color(text-dark)};
      border-radius: get-border-radius(rounded);
      width: calc(50% - 1rem);
      min-width: calc(50% - 1rem);
      max-width: calc(50% - 1rem);
      text-align: center;

      &:first-child {
        color: get-sb-color(text-dark);
        background-color: get-sb-color(transparent);
        border-color: get-sb-color(text-dark);
      }

      &:last-child {
        margin-right: 0;
        color: get-sb-color(text-light);
        background-color: get-sb-color(primary);
        border-color: get-sb-color(primary);
      }

      .alert-button-inner {
        justify-content: center;
        @extend .sb-ticket--modal-title !optional;
        margin-bottom: 0;
        text-transform: none;
      }
    }
  }

  .alert-button--column-group {
    margin-top: 1rem;

    .sb-alert--button {
      width: 100%;
      height: 4.4rem;
      border: $border-width--sm solid #{get-sb-color(text-dark)};
      border-radius: get-border-radius(rounded);

      &:first-child {
        color: get-sb-color(text-light);
        background-color: get-sb-color(secondary);
        border-color: get-sb-color(secondary);
      }
    }
    &.payments {
      .sb-alert--button {
        width: 100%;
        height: 4.4rem;
        border: $border-width--sm solid #{get-sb-color(text-dark)};
        border-radius: get-border-radius(lg); //lg -> 4px;
  
        &:first-child {
          color: get-sb-color(text-light);
          background-color: get-sb-color(primary);
          border-color: get-sb-color(primary);
        }
      }
    }
  }

  &--quota-change {
    ion-backdrop {
      background-color: get-sb-color(background-dark);
      opacity: 0.8 !important;
    }

    .alert-wrapper {
      margin: 0;
      padding: 2.4rem;
      border-radius: get-border-radius(lg) !important;
      box-shadow: none;
      // min-width: 85vw;
    }

    .alert-head,
    .alert-message {
      display: none;
    }

    .alert-radio-group {
      border: none;

      .alert-radio-icon {
        order: unset;
        position: relative;
        display: block;
        left: unset;
        top: unset;
        width: 2.6rem;
        min-width: 2.6rem;
        max-width: 2.6rem;
        height: 2.6rem;
        border-width: $border-width--md;
        border-style: solid;
        border-color: get-sb-color(border-dark);
        border-radius: get-border-radius(circle);

        .alert-radio-inner {
          top: 5px;
          bottom: 5px;
          right: 5px;
          left: 5px;
          width: auto;
          height: auto;
          white-space: normal;
          background-color: get-sb-color(text-dark);
          border: none;
          border-radius: get-border-radius(circle);
        }
      }

      .alert-radio-label {
        margin-bottom: 0;
        padding: 1.2rem 2rem;
        @extend .sb-ticket--modal-subtitle;
        color: get-sb-color(text-dark);
        text-overflow: unset;
        white-space: unset;
      }
    }

    .alert-button-group {
      justify-content: space-between;
      padding: 1.8rem 0 0 0;
      flex-direction: row !important;

      .alert-button {
        margin-right: 1rem;
        border: $border-width--sm solid #{get-sb-color(text-dark)};
        border-radius: get-border-radius(rounded);
        width: calc(50% - 1rem);
        min-width: calc(50% - 1rem);
        max-width: calc(50% - 1rem);
        text-transform: uppercase;
        font-weight: get-font-weight(bold);

        &:first-child {
          color: get-sb-color(text-dark);
          background-color: get-sb-color(transparent);
          border-color: get-sb-color(text-dark);
        }

        &:last-child {
          margin-right: 0;
          color: get-sb-color(text-light);
          background-color: get-sb-color(primary);
          border-color: get-sb-color(primary);
        }

        .button-inner {
          justify-content: center;
          @extend .sb-ticket--modal-title;
          margin-bottom: 0;
          text-transform: none;
        }
      }
    }
  }

  &--documentation {
    ion-backdrop {
      background-color: get-sb-color(background-dark);
      opacity: 0.8 !important;
    }

    .alert-wrapper {
      margin: 0;
      padding: 2.4rem;
      border-radius: get-border-radius(lg) !important;
      box-shadow: none;
    }

    .alert-head {
      padding: 0;
    }

    .alert-title {
      padding: 1rem;
      color: get-sb-color(grey-900);
      font-family: map-get($font-family, roboto-condensed-bold);
      font-size: 1.8rem;
      font-weight: 700;
      margin: 0;
    }

    .alert-message {
      padding: 0 $padding-item-pc $padding-item-pc;

      h2 {
        margin-bottom: 0;
        color: get-sb-color(grey-900);
        font-family: map-get($font-family, roboto-condensed-bold);
        font-size: 1.8rem;
        font-weight: 700;
      }

      p {
        margin-top: 0;
        border: 0;
        color: #5b5b5b;
        font-family: map-get($font-family, roboto-condensed-regular);
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    .alert-button-group {
      justify-content: center;
      padding: 1.8rem 0 0 0;

      .alert-button {
        border: $border-width--sm solid #{get-sb-color(text-dark)};
        border-radius: get-border-radius(lg);
        width: calc(50% - 1rem);
        min-width: calc(50% - 1rem);
        max-width: calc(50% - 1rem);
        margin-right: 0;
        color: get-sb-color(text-light);
        background-color: get-sb-color(primary);
        border-color: get-sb-color(primary);

        .button-inner {
          justify-content: center;
          @extend .sb-ticket--modal-title !optional;
          margin-bottom: 0;
          text-transform: none;
        }

        span {
          justify-content: center;
        }
      }
    }
  }

  &.alertprof {
    .alert-button-group {
      justify-content: center;
    }

    .alert-message {
      overflow-y: auto;
    }

    &.editpref {
      &.sb-alert .alert-button-group .alert-button {
        min-width: calc(80% - 1rem);
        max-width: calc(80% - 1rem);
      }
    }
  }

  &.payments{
    .alert-button-group {
      .alert-button {
        border-radius: get-border-radius(lg); // lg -> 4px;
        &:first-child {
          color: get-sb-color(primary);
          border-color: get-sb-color(primary);
        }
      }
    }
  }
}


.alertcashier {
  .alert-button-group .alert-button {
    margin: auto;
    font-size: 18px;
    font-weight: 600;
  }
  .alert-message {
    .md {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.filterCashierHistory .alert-wrapper {
  button[aria-checked='true'] .alert-radio-label {
    color: #333f48 !important;
  }

  button[aria-checked='true'] .alert-radio-icon {
    border-color: #333f48 !important;
  }
}

.changePasswordAlert {
  .alert-wrapper {
    .alert-head {
      text-align: center;

      ion-icon {
        font-size: 4rem;
        color: #007aff;
        font-weight: 700;
      }

      .alert-title {
        div {
          margin-top: 20px;
          margin-bottom: 20px;
          font-weight: bold;
        }
      }
    }

    .alert-button-group {
      button {
        border-radius: 0;
        border: 2px solid;
        width: auto;
        min-width: auto;
        max-width: none;
      }

      .alert-button:nth-child(1) {
        color: #79c000;
        border-color: #79c000;
      }
    }

    .alert-message {
      text-align: center;
    }
  }
}

.changePasswordAlert {
  .alert-wrapper {
    .alert-head {
      text-align: center;

      ion-icon {
        font-size: 4rem;
        color: #007aff;
        font-weight: 700;
      }

      .alert-title {
        div {
          margin-top: 20px;
          margin-bottom: 20px;
          font-weight: bold;
        }
      }
    }

    .alert-button-group {
      button {
        border-radius: 0;
        border: 2px solid;
        width: auto;
        min-width: auto;
        max-width: none;
      }

      .alert-button:nth-child(1) {
        color: #79c000;
        border-color: #79c000;
      }
    }

    .alert-message {
      text-align: center;
    }
  }
}

.verification-alert {
  .alert-title {
    color: transparent;
    background-image: url('../img/icon-circle-info.svg');
    background-repeat: no-repeat;
    background-size: 42px;
    padding-bottom: 32px;
    background-position: center;
  }

  .alert-wrapper {
    max-width: 440px !important;

    .alert-message {
      max-height: 500px;
      color: rgba(0, 0, 0, 0.7);
      overflow: hidden;
      text-align: center;
      font-weight: 700;
      font-size: 1.2em;

      .centerQRCode {
        display: block;
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;
        width: 30%;
      }
    }

    .alert-button-group {
      max-width: 540px;
      max-height: 500px;
      justify-content: center;
      padding: 8px 8px 40px 8px;

      button {
        margin: 0;
        font-size: 1.2em;
        border-radius: 5px;
        font-weight: 600;
      }
    }
  }
}

.verification-guide {
  .alert-title {
    color: transparent;
    background-image: url('../img/icon-circle-info.svg');
    background-repeat: no-repeat;
    background-size: 36px;
    padding-bottom: 32px;
    background-position: center;
  }

  .alert-sub-title {
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: center;
  }

  .alert-wrapper {
    max-width: 440px !important;
  }

  .alert-button-group {
    max-width: 540px;
    max-height: 500px;
    justify-content: center;
    padding: 8px 8px 40px 8px;

    .corner-close-icon {
      text-align: right;
      position: absolute;
      top: 6px;
      right: 9px;
      z-index: 1000;
      color: #000;
      padding: 0px;
      background-color: transparent;
    }

    .alert-button {
      margin-inline-end: 0;
      text-align: center;
    }

    button {
      border-radius: 8px;
      font-weight: 600;
    }
  }

  .alert-message {
    max-height: 500px;
    color: rgba(0, 0, 0, 1);
    overflow: hidden;
    font-size: 15px;
    text-align: left;

    li {
      margin-bottom: 16px;
    }

    li::marker {
      color: green;
      font-weight: 800;
    }

    .centerQRCode {
      display: block;
      margin-top: 24px;
      margin-left: auto;
      margin-right: auto;
      width: 30%;
    }

    .mobile {
      li {
        &:last-child {
          display: none;
        }
      }
    }
  }
}

.welcome-modal .alert-footer {
  max-width: 250px;
  padding-top: 10px;
}
.welcome-modal,
.loginko {
  .alert-button:last-child {
    border-radius: 5px;
  }
}
.loginkobtn {
    border-radius: 5px !important;
}

.alert-footer {
  margin: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a:first-of-type {
    flex: 1.3 0 10%;
  }

  a:not(:first-of-type) {
    flex: 2 0 10%;
  }

  img {
    width: 100%;
    padding-right: 10px;
  }

  img.bordery {
    border-left: 2px solid #fcc100;
  }
}

.arg-button-group {
  margin-bottom: 7rem;
}

.oculto {
  visibility: hidden;
}
.alertokbonus {
  &.alertWbInOne {
    .alert-message {
      text-transform: inherit;
    }
  }
  z-index: 99999999 !important;
  .alert-wrapper {
    padding-bottom: 20px;
  }
  .alert-message {
    text-transform: uppercase;
    color: #616161;
    font-weight: 700;
    text-align: center;
    font-size: 1.5rem;
    .iconalert {
      text-align: center;
    }
  }
  .alert-button-group {
    margin: 0 auto;
    justify-content: center;
    width: auto;
    margin-bottom: 10px;
  }
  .alert-button {
    border-radius: 4px !important;
    padding: 10px 30px !important;
    height: 3.5rem !important;
  }
  .alert-footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    text-transform: none;
    a {
      color: map-get($sb-colors, secondary);
      font-weight: 700;
      font-size: 1.1rem;
    }
  }
  .alert-head.sc-ion-alert-md {
    padding-top: 0 !important;
  }
  .closeIcon {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
  }
}

.backgroundheader {
  &.alertokbonus {    
    .alert-button {
      border-radius: 5px !important;          
    }
  } 
  &.btnconfirmcasino {
    .alert-button {
      background-color: map-get($sb-colors, casprimary);
    }
    .iconalert img{
      filter: invert(49%) sepia(94%) saturate(3726%) hue-rotate(267deg) brightness(86%) contrast(91%)
    }
  }
  &.closedwelcomebonus{
    .alert-button-group {
      .alert-button {
        border-radius: 5px;
      }
    }
  }
}
.closedwelcomebonus {
  .alertMessageCustom {
    .iconalert {
      text-align: center;
    }
  }
  .txtalertMessageCustom {
    text-transform: uppercase;
    color: #616161;
    font-weight: 700;
    text-align: center;
  }
  .alert-button-inner.sc-ion-alert-md {
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.5rem;
  }
  .alert-button-group {
    flex-direction: row;
  }
  .alert-button-group.sc-ion-alert-ios {
    flex-direction: row;
  }
}
.txtgreen {
  color: #79c000;
  font-weight: 700;
}
.txtred {
  color: red;
  font-weight: 700;
}
.alertrevocationse,
.alertrevocationselogin {
  .bold {
    font-weight: 700;
  }
  .alert-button-group {
    justify-content: center;

    button {
      text-align: center;
    }
  }
  .alert-button:last-child {
    border-radius: 5px;
    background: get-sb-color(secondary);
    padding: 10px 25px;
    margin-bottom: 25px;
  }
  .alert-message {
    text-align: center;
    overflow-y: auto;
    font-size: 1.6rem;
  }
}
.alertrevocationselogin {
  max-width: 360px !important;
  .alert-head {
    background: #79c000;
    .alert-title {
      color: #fff;
      font-weight: 700 !important;
      text-transform: none;
      font-size: 2rem !important;
    }
    i {
      position: absolute;
      right: -4px;
      top: 20px;
      color: #fff;
      font-size: 1.75rem;
    }
  }
  .alert-message {
    font-size: 1.75rem;
    margin: 15px 0;
    text-align: center;
  }
}

.is-loader .loading-wrapper ion-spinner {
  color: black; // Cambia el color del spinner
}

.alert-button-group.sc-ion-alert-ios {
  button {
    display: flex;
    justify-content: center;
  }
}
.filterCashierHistory {
  .alert-button-group.sc-ion-alert-ios {
    flex-direction: row;
  }
}

.alertProfesion .alert-button-group.sc-ion-alert-ios {
  flex-direction: row;
}

.platform-mobile {
  ion-alert.select-alert .alert-button-inner {
    .alert-radio-icon {
      inset-inline-start: 13px;
      width: 16px;
      height: 16px;
      .alert-radio-inner {
        left: 2px;
        top: 2px;
        width: 8px;
        height: 8px;
      }
    }
    .alert-radio-label {
      padding-left: 26px !important;
    }
  }
}

ion-alert.alertProfesion {
  .alert-wrapper {
    border-radius: 5px !important;

    .alert-button-inner {
      .alert-radio-label {
        padding-left: 13px !important;
      }
      .alert-radio-icon {
        inset-inline-start: 0px !important;
      }
    }
  }

  .alert-button-group.sc-ion-alert-ios {
    flex-direction: row;
  }
}

.displayrow .alert-button-group.sc-ion-alert-ios {
  flex-direction: row;
}
// Class used in confirmation alert to cashout and close bet
.cashout-confirmation-alert {
  .alert-button-group {
    flex-direction: row;
  }
  .alert-message {
    text-align: unset;
  }
}
.alert-button-inner.sc-ion-alert-md {
  justify-content: center !important;
}

.alert-button.sc-ion-alert-md.alert-button-role-cancel {
  border: 1px solid get-sb-color(primary);
  border-radius: get-border-radius(lg); //lg -> 4px;
}

.mendozaSportAlertReg {
  .closeIcon {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 20px;
  }
}
.btnalertrow {
  .alert-button-group.sc-ion-alert-ios {
    flex-direction: row;
  }
}
.alertPromotionsOk, .alertPromotionsKo {
  .alert-wrapper{
    border-radius: 10px;
  }

  .alert-head{
    display: none;
  }

  .closeIcon{
    position: absolute;
    top: 15px;
    right: 18px;
    font-size: 2rem;
    font-weight: 700;
    color: #333f48;
    cursor: pointer;
  }

  .alert-message{
    text-align: center;
    display: flex;
    flex-direction: column;

    img {
      align-self: center;
      margin-top: 2rem;
    }

    ul {
      padding: 0px 20px 0px 20px;
      list-style: none;
    }

    a{
      cursor: pointer;
    }
  }

  .alert-sub-title{
    text-align: center;
    font-weight: 700;
    font-size: 2.3rem;
    color: #333F48;
  }

  .alert-button-group {
    justify-content: center;
    padding: 8px 8px 8px 8px;

    button{
      flex: 0.9;
      margin: 0 0 12px 0;
      span {
        justify-content: center !important;
      }
      .button-inner {
        font-size: 2rem;
        font-weight: 300;
      }
    }
  }

  &.newRebrandingCss{
    button {
      border-radius: 5px;
    }   
  }

  &:not(.newRebrandingCss){
    button{
      border-radius: 25px;
    }
  }

}

.alertPromotionsOk {
  .alert-message{
    font-size: 2.3rem;
    font-weight: 700;
    color: #333f48;
    line-height: 3rem;

    img{
      margin-bottom: 2rem;
    }
  }
  .alert-button-group {
    button{
      height: 4rem;
      font-size: 2rem;
    }
  }
  .checkgreen {
    color: #79c000;
    max-width: 60px;
  }
}

.alertPromotionsKo {
  
  .alert-message {    
      color: rgba(0, 0, 0, 0.9);
      max-height: fit-content;
      text-align: center !important;
      padding: 0 24px 10px;
    a {
      color: map-get($sb-colors, secondary);
    }
    h1{
      margin: 0px;
    }
    img {
      margin-top: 2rem;
    }
  }

  .alert-button-group {
    button {
      background-color: map-get($sb-colors, secondary);
      height: 5.5rem;
      font-size: 1.8rem;
      .btnPromoOK {
        height: 4rem;
      }
    }
  }
  
  .alert-wrapper {
    max-width: 310px !important;
  }
}
.alertSelfexclusionKO {
  .closeIcon {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
  }
  .alert-button-group {
    justify-content: center;
  }
}
