.sb-grid-layout {
  &.has-two-items {
    @include flex-row(flex-start, flex-start, wrap);

    sb-live-scoreboard-baseball.sb-grid-layout--item,
    sb-live-scoreboard-regular.sb-grid-layout--item,
    sb-live-scoreboard-sets.sb-grid-layout--item {
      min-width: unset;
    }

    // .sb-live--streaming-video.sb-grid-layout--item {
    //   flex: 1 0 35%;
    // }

    .sb-grid-layout--item {
      flex: 1 0 50%;
      max-width: 50%;
      height: $height--video-streaming-desktop;

      .sb-live-scoreboard-baseball,
      .sb-live-scoreboard,
      .sb-live-scoreboard-sets {
        height: $height--video-streaming-desktop;
        max-height: $height--video-streaming-desktop;
      }

      // .sb-live-scoreboard-baseball--container,
      // .sb-live-scoreboard-regular--container,
      // .sb-live-scoreboard-sets--container {

      //   @include sb-breakpoint(null, 576px) {
      //     min-width: 90%;
      //   }

      // }
    }
  }
}

.platform-core {
  .sb-grid-layout.has-two-items {
    flex-wrap: nowrap;
  }

  .tablet-common-grid,
  .sb-grid-main--desktop {
    padding: 0;
    height: 100%;
    margin: 0;
    max-height: 100vh !important;

    .aside {
      overflow: scroll;
    }

    > ion-row {
      padding: 0;
      margin: 0;
      height: 100%;

      > ion-col {
        height: calc(100vh - 58px);
        padding: 0;
        z-index: 1;

        &.menu-web {
          max-width: 15%;
        }
      }
    }

    .menu-web {
      flex: 0 0 15%;
      max-width: 15%;
    }
  }
}

// @mixin aspect-ratio($width, $height) {
//   position: relative;

//   &:before {
//     content: "";
//     display: block;
//     width: 100%;
//     padding-top: ($height / $width) * 100%;
//   }

//   >.content {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//   }

// }
