﻿ion-item {
  &.item-md {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
    font-size: 1.6rem;
    font-weight: 400;
    text-transform: none;
    color: #000;
    background-color: #adb2b5;
    box-shadow: none;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    p {
      margin: 0 0 2px;
      overflow: inherit;
      font-size: 1.4rem;
      line-height: normal;
      text-overflow: inherit;
      color: #666;
    }
  }

  &.payment-item {
    width: 100%;
    max-height: 56px;
    background-color: #fff;
    padding: 0 $outer-gutter-mobile;
    margin-bottom: $outer-gutter-mobile;
    border: 0;
    box-shadow: $item-shadow-mobile;

    &.opened {
      margin-bottom: 0;
    }

    img {
      max-height: 35px;
    }

    i {
      font-size: 4rem;
      color: get-sb-color(primary);
    }

    p {
      margin: 0;
      @include font(roboto-condensed-regular, main, normal);
      color: #616161;
    }

    ion-label {
      @include flex(flex, flex-start, center, row, nowrap);
      margin: 0;
    }
  }

  &.legal-item {
    background-color: transparent;
    padding: 0;
    overflow: visible;
    margin-bottom: $outer-gutter-mobile;
    border-bottom: 1px solid #dedede;

    &.has-bullet {
      ion-label {
        @include flex(inline-flex, flex-start, center, row, nowrap);
        min-height: 56px;
        padding: $outer-gutter-mobile;
      }

      .bullet {
        position: relative;
        left: 0;

        p {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          border-width: 1px;
          border-style: solid;
          border-color: get-sb-color(secondary);
          color: get-sb-color(secondary);
          font-size: 1.5rem;
          text-align: center;
          line-height: 29px;
        }
      }

      .legal-message {
        padding-left: $outer-gutter-mobile;
      }
    }

    .item-inner {
      padding: 0;
    }

    ion-label {
      overflow: visible !important;
      white-space: normal;
      margin: 0;
      @include font(roboto-regular, main-subtitle, normal);
      color: #5b5b5b;
    }
  }

  &.ticket-item {
    background: transparent;
    padding: 0;
    border-bottom: 1px solid #dedede;

    &:last-child {
      border-bottom: 0;
    }

    &.info-inline {
      ion-label {
        @include flex(inline-flex, flex-start, center, row, nowrap);
      }
    }

    .item-inner {
      padding: 0;
      margin: 0;
    }

    .input-wrapper {
      padding: 0;
    }

    ion-label {
      margin: 0 !important;
    }

    .eventAp {
      @include font(roboto-condensed-regular, main, normal);
      color: #212121;
      @include text-ellipsis(95);
    }

    .typeAp {
      padding-top: $inner-gutter-mobile;
      @include font(roboto-regular, title, normal);
      color: #9e9e9e;
      @include text-ellipsis(95);
    }
  }

  &.card-item {
    background: transparent;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #dedede;

    &:last-child {
      border-bottom: 0;
    }

    .item-inner {
      padding: 0 !important;
    }

    ion-label {
      h2 {
        width: 100%;
        margin: 0;
        @include font(roboto-medium, title, bold);
        letter-spacing: 0.01rem;
        line-height: 1.85rem;
        white-space: normal;
        color: get-sb-color(primary);
      }
    }

    ion-avatar {
      padding: 1.2rem 1.4rem 1.4rem;
      margin-left: 12px !important;
      background-color: #f3f3f3;
      border-radius: 50%;
      box-shadow: -6px 6px 20px -6px rgba(0, 0, 0, 0.33);
      border: 2px solid #fff;
      height: 4.25rem;
      width: 4.25rem;
    }

    .arrow {
      right: 3%;
      margin: 0;
      top: 17%;
      font-size: 2.5rem;
      position: absolute;
      color: #ababad;
    }

    .lblrequest {
      color: #888787;
      font-size: 1.4rem;
      width: 100%;
      padding: 1rem;
      padding-top: 0;
      white-space: normal;
      margin-top: 0;
      letter-spacing: 0.02rem;
      padding-bottom: 1.75rem;
      margin-bottom: 0;
    }

    &.card-option {
      padding: $outer-gutter-mobile 0 0;
      border: 0;

      ion-avatar {
        @include flex(flex, center, center, row, nowrap);
        padding: 0;
        margin: 0 !important;
        border: 0;
        border-radius: 50%;
        box-shadow: none;
        background: get-sb-color(grey-200);

        i {
          font-size: 2.5rem;
          color: get-sb-color(secondary);
        }
      }

      .item-inner {
        @include flex(flex, flex-start, center, row, nowrap);
        padding: $inner-gutter-mobile 0;
        margin-left: $outer-gutter-mobile;
        border-bottom: 1px solid #dedede;

        &:last-child {
          border-bottom: 0;
        }

        .arrow {
          position: relative;
          top: 0;
          right: 0;
          font-size: 2.4rem;
          margin: 1rem;
          margin-right: 0;
          color: #bfbfbf;
        }
      }

      ion-label {
        h2 {
          @include font(roboto-regular, title, normal);
          color: #7d7d7d;
          @include text-ellipsis(95);
        }
      }
    }
  }
}

.platform-core {
  ion-item {
    &.league-item {
      background: #fff;
      padding: 0;
      margin: 0;

      .input-wrapper {
        padding: 0;
        margin: 0;
      }

      ion-label {
        padding: 0;
        margin: 0;
        @include text-break();
      }

      ion-avatar {
        margin: 0;
        border-radius: 50%;

        img {
          max-width: 2.8rem;
          max-height: 2.8rem;
        }
      }

      h1 {
        margin: 0;
        @include font(roboto-condensed-regular, title, bold);
        color: #848484;
      }
    }

    &.league-subitem {
      padding: 0;
      margin: 0;
      background: #eee;
      @include border('bottom', 1px, solid, grey-400);

      &:last-child {
        border-bottom: 0 !important;
      }

      ion-label {
        padding: 0;
        margin: 0;
        @include text-break();
      }

      ion-avatar {
        margin: 0;
        border: 0;
      }

      .flag {
        @include flex(inline-flex, flex-start, center, row, nowrap);
        margin: 0;
        color: #616161;

        img {
          max-width: 2rem;
          max-height: 2rem;
          margin: 0 6px;
          border: 0;
          box-shadow: none;
        }
      }

      h2 {
        @include font(roboto-condensed-regular, subtitle, normal);
        color: #848484;
      }
    }
  }
}
