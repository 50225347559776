/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// --------------------------------------------------
// All font sizes use rem units
// By default, 1rem equals 10px. For example, 1.4rem  === 14px
// $font-size-root value, which is on the <html> element
// is what can scale all fonts

/// @prop - Font size of the root html
$font-size-root: 62.5% !default;

/// @prop - Font weight of all headings
$headings-font-weight: 500 !default;

/// @prop - Line height of all headings
$headings-line-height: 1.2 !default;

/// @prop - Font size of heading level 1
$h1-font-size: 2.6rem !default;

/// @prop - Font size of heading level 2
$h2-font-size: 2.4rem !default;

/// @prop - Font size of heading level 3
$h3-font-size: 2.2rem !default;

/// @prop - Font size of heading level 4
$h4-font-size: 2rem !default;

/// @prop - Font size of heading level 5
$h5-font-size: 1.8rem !default;

/// @prop - Font size of heading level 6
$h6-font-size: 1.6rem !default;

video-player #video-player {
  height: 100%;
}

.codere-custom-input-style.light .input-clear-icon.sc-ion-input-md ion-icon.sc-ion-input-md {
  color: #ffffff;
}

.codere-custom-input-style.dark .input-clear-icon.sc-ion-input-md ion-icon.sc-ion-input-md {
  color: #333f48;
}

@import './sports-overrides.scss';
