@import 'src/app/modules/shared/assets/global/styles/base/index';
@import 'src/app/modules/shared/assets/global/styles/base/sb-variables.scss';

$codere-accent: #0056fe;
$codere-light: #fff;

[hidden] {
  display: none !important;
}

.auto-height {
  height: auto !important;
}

ion-item::part(native) {
  --padding-start: 0px;
}

.sb-ticket {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: flex-start;
  background: get-color(background-sets);

  &--cape {
    width: 100vw;
    height: 100vh;
  }

  &--collapsed {
    @include offset(fixed, lg, null, 0, 0, 0);
  }

  &--modal {
    @include offset(absolute, xl, 0, 0, 0, 0);
    @include flex(flex, center, center, row, wrap);
    background: rgba(50, 63, 71, 0.8);

    &-close {
      @include offset(absolute, xl, 8px, 0, null, null);
      margin: 2rem;
      padding: 0;
      width: 2.2rem;
      height: 2.2rem;
      font-family: $font-family-secondary;
      border-radius: get-border-radius(circle);

      &::before {
        @include pseudo('/e968', absolute, null, 0, 0, 0, 0);
        font-size: 2.4rem;
        color: get-sb-color(grey-800);
      }
    }

    &-content {
      position: relative;
      @include flex(flex, center, flex-start, column, wrap);
      margin-left: $margin--base;
      margin-right: $margin--base;
      padding: ($margin--base * 3) ($margin--base * 2);
      width: 100%;
      max-width: 40rem;
      border-radius: get-border-radius(lg);
    }

    &-freebets {
      @include flex(flex, null, null, column, nowrap);
      margin-top: $margin--base;
      max-height: calc(4.4rem * 5);
      overflow-y: auto;
      width: 100%;
    }

    &-actions {
      @include flex(flex, space-between, center, row, nowrap);
      width: 100%;

      .sb-ticket--button-modal {
        width: 48%;
      }
    }
  }

  &--bar {
    @include flex(flex, center, flex-end, row, nowrap);
    padding-bottom: 2px;
    width: 100%;
    height: 20px;

    &-icon {
      display: block;
      width: 8.4rem;
      height: 0.8rem;
      border-radius: 50px;
    }
  }

  &--header {
    @include flex(flex, space-between, center, row, nowrap);
    padding-left: $margin--base;
    padding-right: $margin--base;
    height: $height-ticket-header;

    &-button {
      padding: 0.6rem 0;
      min-width: 9.6rem;
      min-height: 3.8rem;
      max-height: 3.8rem;
      background: get-sb-color(transparent);
      border-width: $border-width--sm;
      border-style: solid;
      border-radius: 50px;
    }
  }

  &--empty-state {
    @include flex(flex, center, center, row, nowrap);
    margin-top: 1px;
    padding: 3rem;
    width: 100%;
    height: 102px;
    text-align: center;
  }

  &--filter {
    position: relative;
    display: block;
    width: 100%;
    height: $height-ticket-filter;
    .sb-filter--title {
      font-size: 1.6rem !important;
    }
  }

  &--banner {
    @include flex(flex, space-between, center, row, nowrap);
    padding-left: 2px;
    padding-right: $margin--base * 2;
    min-height: 6rem;

    .sb-ticket--banner-title {
      flex-basis: 25%;
      margin-right: $margin--base;
      padding: 0.7rem 0.9rem 0.3rem;
      background-color: get-sb-color(secondary);
      border-radius: get-border-radius(sm);
    }

    .sb-ticket--banner-subtitle {
      flex-basis: 75%;
    }

    .icon {
      margin-right: -4px;
      font-size: 2.5rem;
    }

    .codere-icon {
      @include offset(absolute, null, null, 1.8rem, null);
    }
  }

  &--row {
    @include flex(flex, space-between, center, row, nowrap);
    padding-top: $margin--base;
    padding-bottom: 0;
    border-bottom-width: $border-width--sm;
    border-bottom-style: solid;
    border-bottom-color: get-sb-color(transparent);

    &-toggle {
      @extend .sb-ticket--row;
      padding-top: unset;
      padding-bottom: unset;
      min-height: 4.4rem;
      max-height: 4.4rem;
      border: none;
    }

    &-confirmation {
      @extend .sb-ticket--row;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 0.8rem;
      padding: unset;
      min-height: 4.2rem;
      max-height: 4.2rem;
      border: none;
    }

    &-final {
      @extend .sb-ticket--row;
      position: relative;
      padding-top: unset;
      min-height: 7.8rem;
      max-height: 7.8rem;
      border: none;
      .sb-ticket--button-stake {
        position: relative;

        margin-bottom: 0.5rem;
        padding: 0.6rem 1rem;
        min-height: 4.4rem;
        max-height: 4.4rem;
        border-width: 1px;
        border-style: solid;
        border-radius: 50px;
      }
    }
  }

  &--content {
    @include flex(flex, flex-start, center, row, nowrap);
  }

  &--delete {
    @include flex(flex, flex-end, center, row, nowrap);
    flex: 1;
    margin-right: -$margin--base;
    margin-top: 5px;
  }

  &--resume {
    @include offset(absolute, sm, null, 0, 0, 0);
    @include flex(flex, space-between, center, row, nowrap);
    padding-left: ($margin--base + 0.2rem);
    padding-right: ($margin--base + 0.2rem);
    box-shadow: $box-shadow--ticket-up;

    &.swipe-bar {
      @include pseudo('', absolute, null, -11px, null, null, 50%);
      display: block;
      width: 8.4rem;
      height: 0.3rem;
      background: lightgray;
      border-radius: 50px;
      transform: translateX(-4.2rem);
    }

    &-bullet {
      @include flex(flex, center, center, row, nowrap);
      margin-right: $margin--base;
      width: 3.4rem;
      height: 3.4rem;
      border-radius: get-border-radius(xl);
    }
  }

  &--select {
    padding: 0.8rem $margin--base;
    width: 100%;
    min-height: 6rem;
  }

  &--actions {
    position: relative;
    z-index: get-z-index(xl);
    padding-left: $margin--base;
    padding-right: $margin--base;
    border-top-width: $border-width--sm;
    border-top-style: solid;
    transition: $transition--sb-translate;
    .sb-ticket &.box-shadow {
      box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.5);
    }
  }

  &--toggle {
    @include flex(flex, flex-start, center, row, nowrap);

    &-button {
      flex: 1;
      justify-content: flex-end;
      margin-right: 10px !important;
      padding: 0;
      height: 45px;
      margin-top: 1px;

      .toggle-ios.toggle-disabled,
      .toggle-md.toggle-disabled {
        opacity: 1;
      }

      @include sb-breakpoint(null, ticket-toggle) {
        margin-right: 6px !important;
      }

      &.input-has-focus {
        border: none !important;
      }
    }

    .item-inner {
      flex: initial;
      padding-right: $margin--base;
      border: none !important;
      padding: 0 !important;
    }

    &-icon {
      padding: 0 !important;
      width: 4.9rem;
      height: 2.8rem;
      --handle-background-checked: var(--handle-background);

      &.sb-ticket--toggle-icon-opacity {
        opacity: 0.3 !important;
      }

      .toggle-icon {
        background-color: get-sb-color(background-regular);

        &::before {
          background-color: get-sb-color(background-regular);
        }
      }

      .toggle-inner {
        width: 2.4rem !important;
        height: 2.4rem;
        background-color: get-sb-color(background-button);
        box-shadow: none;
      }

      &.toggle-md {
        .toggle-inner {
          top: 0.2rem;
          left: 0.2rem;
        }
      }

      &.toggle-ios,
      &.toggle-md {
        &.toggle-checked {
          .toggle-inner {
            background-color: get-sb-color(background-button);
            transform: translate3d(20px, 0, 0);
          }

          .toggle-icon {
            background-color: get-sb-color(primary) !important;
          }
        }
      }
    }
  }

  &--confirmation {
    &-header {
      position: relative;
      @include flex(flex, space-between, center, row, nowrap);
      min-height: 6.6rem;

      .codere-icon {
        padding: $margin--base;
        transition: $transition--sb-hover;
      }
    }

    &-headings {
      flex: 1;
      z-index: get-z-index(sm);
      max-width: calc(100vw - 44px);
    }

    &-footer {
      @include flex(flex, space-between, center, row, nowrap);
      width: 100%;

      &:not(:last-child) {
        margin-bottom: calc($margin--base / 2);
      }
    }
  }

  &--button {
    position: relative;
    margin-bottom: 0.5rem;
    padding: 0.6rem $margin--base;
    min-height: 4.4rem;
    max-height: 4.4rem;
    border-width: $border-width--sm;
    border-style: solid;
    border-radius: 50px;

    &[mode='locked'] {
      pointer-events: none;

      &::before {
        @include pseudo(
          '',
          absolute,
          lg,
          -$border-width--sm,
          -$border-width--sm,
          -$border-width--sm,
          -$border-width--sm
        );
        background: get-sb-color(background-dark);
        opacity: 0.7;
      }
    }

    &-modal {
      @extend .sb-ticket--button;
      margin-top: $margin--base;
      margin-bottom: $margin--base;
      min-width: 12.4rem;
    }

    &-settings {
      @extend .sb-ticket--button;
      flex-basis: 30%;
      @include flex(flex, center, center, row, nowrap);
      margin-right: $margin--base;

      @include sb-breakpoint(sm, null) {
        flex-basis: 40%;
      }

      .codere-icon {
        font-size: 2.5rem;
      }

      .sb-ticket--button-title {
        flex-basis: inherit;
        margin-left: 0.5rem;
        min-width: 7rem;
      }
    }

    &-warning {
      @extend .sb-ticket--button;
      flex-basis: 70%;
      @include flex(flex, center, center, row, nowrap);
      padding-top: 0.5rem;
      overflow: hidden;

      @include sb-breakpoint(sm, null) {
        flex-basis: 60%;
      }

      .alert-icon {
        margin-bottom: 2px;
        font-size: 2.5rem;
      }

      .ion-ios-alert-outline::before {
        font-size: 2.5rem;
      }

      .sb-ticket--button-title {
        flex: 1;
        flex-basis: inherit;
        margin-left: 0.5rem;
        min-width: 7rem;
        text-align: left;
      }
    }

    &-selection {
      @extend .sb-ticket--button;
      flex-basis: 50%;
      margin-right: $margin--base;
      padding-top: 0.5rem;

      @include sb-breakpoint(sm, null) {
        flex-basis: 40%;
      }
    }

    &-bet {
      @extend .sb-ticket--button;
      flex-grow: 1;
      flex-basis: 65%;
      padding-top: 0.5rem;

      @include sb-breakpoint(sm, null) {
        flex-basis: 60%;
      }
    }

    &-inline {
      @extend .sb-ticket--button;
      flex: unset;
      display: inline-flex;
      margin-right: 1rem;
      text-transform: uppercase;
      border: none;
      color: white;

      .sb-ticket--modal & {
        margin-bottom: $margin--base;
        text-transform: unset;

        &:last-child {
          margin-bottom: 0;
        }

        .sb-ticket--button-title {
          font-size: get-font-size-mobile(16);
        }
      }

      .icon-close {
        margin-top: 1px;
        margin-left: $margin--base;
        font-size: get-font-size-mobile(md);
      }
    }
  }

  &--races {
    padding: $margin--base;
    @include flex(flex, null, null, row, nowrap);

    &-button {
      display: block;
      // flex-basis: calc(50% - #{$margin--base / 2}); // Deprecation Using / for division outside of calc() is deprecated and will be removed in Dart Sass 2.0.0.
      $margin-calculation: $margin--base * 0.5;
      flex-basis: calc(50% - #{$margin-calculation});
      height: 4.4rem;
      @include sb-title-xl;
      font-weight: get-font-weight(regular);
      font-size: get-font-size-mobile(18);
      line-height: get-line-height(sm);
      color: get-sb-color(light);
      border-radius: get-border-radius(sm);

      &:first-of-type {
        margin-right: calc($margin--base / 2);
        background: get-sb-color(background-base);
      }

      &:last-of-type {
        margin-left: calc($margin--base / 2);
        background: get-sb-color(primary);
      }
    }
  }
}

[mode='ticket-simple'] {
  &.sb-ticket--collapsed {
    .sb-ticket--selections {
      &.swipe-bar {
        &::after {
          @include pseudo('', absolute, null, -11px, null, null, 50%);
          display: block;
          width: 8.4rem;
          height: 0.8rem;
          background: get-sb-color(background-dark);
          border-radius: 50px;
          transform: translateX(-4.2rem);
        }
      }
    }

    .sb-ticket-selection {
      &:first-child {
        border-radius: 6px 6px 0 0;
      }
    }
  }
}

[mode='ticket-expanded'] {
  .sb-ticket--filter {
    &::before {
      @include pseudo('', absolute, sm, 0, -$margin--base, 0, -$margin--base);
      height: 100%;
      box-shadow: $box-shadow--ticket-down;
    }
  }
}

[mode='ticket-expanded-multiselection'] {
  .sb-ticket--filter {
    &::before {
      @include pseudo('', absolute, sm, 0, -$margin--base, 0, -$margin--base);
      height: 100%;
      box-shadow: $box-shadow--ticket-down;
    }
  }

  .sb-ticket-multiselection--list {
    margin: 0;
    padding: 0;
    z-index: 9999;
  }

  .sb-grid-filter {
    .sb-grid-filter--content {
      max-height: 0;
    }
  }

  .sb-grid-filter.is-open {
    &::after {
      z-index: get-z-index(lg);
      top: $height-app-header;
      opacity: 0.8;
    }

    .sb-grid-filter--content {
      max-height: calc(100vh - #{$height-app-header + 10px + $height-ticket-filter + 10px + $height-ticket-actions});
    }
  }

  .sb-grid-filter--item {
    .sb-grid-filter--icon {
      display: none;
    }

    .sb-grid-filter--title {
      margin-left: 0;
    }
  }

  .sb-grid-filter--content {
    z-index: get-z-index(xl);
    position: absolute;
    bottom: 5rem;
    left: $margin--base;
    right: $margin--base;
    height: auto;
  }

  .sb-ticket--actions {
    z-index: 4;
  }
}

[mode='confirmation-simple'] {
  top: unset;
  @include flex(flex !important, flex-end, null, column, nowrap);

  .sb-ticket--confirmation-header {
    border-radius: 6px 6px 0 0;

    &::before {
      @include pseudo('', absolute, sm, null, -$margin--base, 0, -$margin--base);
      height: $border-width--sm;
      background: get-sb-color(border-light);
    }
  }
}

[mode='confirmation-collapsed'] {
  top: unset;
  padding-bottom: $height-ticket-confirmation;
  max-height: 19.5rem;

  &.sb-ticket--collapsed {
    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 9999999999;
    }
  }

  .sb-ticket--confirmation-header {
    border-radius: 6px 6px 0 0;

    .codere-icon {
      transform: rotate(-180deg);
    }
  }
}

[mode='overask-rejected'] {
  top: unset;
  padding-bottom: $height-ticket-overask;
  max-height: 19.5rem;

  &.sb-ticket--collapsed {
    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 0;
    }
  }

  .sb-ticket--confirmation-header {
    border-radius: 6px 6px 0 0;

    .codere-icon {
      transform: rotate(-180deg);
    }
  }
}

[mode='confirmation-expanded'] {
  .sb-ticket--confirmation-header {
    &::before {
      @include pseudo('', absolute, sm, 0, -$margin--base, 0, -$margin--base);
      height: 100%;
      box-shadow: $box-shadow--ticket-down;
    }
  }

  .sb-ticket--actions {
    box-shadow: $box-shadow--ticket-up;
  }
}

[keyboard='active'] {
  &.sb-ticket--collapsed .sb-ticket--selections {
    &::after {
      visibility: hidden;
    }
  }
}

:host-context(.platform-mobile) {
  /// Animations

  .sb-ticket--collapsed {
    &.swipeable {
      pointer-events: none;
    }

    &.hidden {
      opacity: 0;
    }

    &.active {
      &::before {
        display: none;
      }
    }
  }

  #sb-ticket {
    #ticket-selections {
      background-color: get-sb-color(grey-900);
    }
  }

  #ticket-selections {
    div.sb-ticket-selection-group {
      border-bottom-color: get-sb-color(grey-800);
      border-bottom-width: $border-width--sm;
      border-bottom-style: solid;
      margin: 0 $margin--base;
    }
  }

  /// Ticket Collapsed animation

  /// Ticket Resume animation
  .sb-ticket--resume {
    animation: decrease $transition-timing--lg ease-out normal forwards;

    @keyframes decrease {
      from {
        min-height: 19.2rem;
      }

      to {
        min-height: 7.8rem;
      }
    }
  }

  /// Ticket Resume animation

  /// Backdrop
  .sb-ticket[mode='confirmation-expanded'],
  .sb-ticket--collapsed[mode='confirmation-simple'] {
    pointer-events: all;

    &::after {
      @include pseudo('', fixed, xs, $height-app-header, 0, 0, 0);
      display: block;
      background: transparent;
      opacity: 0.8;
      animation: fade $transition-timing--xl ease-out normal forwards;
      pointer-events: all;

      @keyframes fade {
        from {
          visibility: hidden;
        }

        to {
          visibility: visible;
        }
      }
    }
  }

  /// Backdrop

  /// Animations
  .sb-ticket--confirmation-header {
    border-radius: 6px 6px 0 0;
    border-bottom: $border-width--sm solid get-sb-color(grey-800);
    margin-bottom: -1px;
    z-index: 6;
  }

  .sb-ticket-multiselection--list {
    display: block;
  }
}

:host-context(.platform-core) {
  .sb-ticket {
    position: relative;
    display: block;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    // height: 100%;
    height: calc(100% - 45px);
    overflow-y: auto;
    overflow-x: hidden;

    &--header {
      position: sticky;
      top: 0;
      z-index: get-z-index(xl);
      height: 64px;
      border-bottom-width: $border-width--sm;
      border-bottom-style: solid;

      .sb-ticket--toggle-title {
        font-size: get-font-size-mobile(18);
      }

      &-button {
        display: none;
      }
    }

    &--filter {
      position: sticky;
      top: 0px;
      z-index: get-z-index(xl);

      &::before {
        box-shadow: unset;
      }

      .sb-filter--title {
        font-size: get-font-size-mobile(16);
      }
    }

    &--selections {
      .sb-ticket--delete {
        display: none;
      }

      #ticket-selections {
        div.sb-ticket-selection-group {
          border-bottom-color: get-sb-color(grey-800);
          border-bottom-width: $border-width--sm;
          border-bottom-style: solid;
          margin: 0 $margin--base;

          &:last-of-type {
            border-bottom-width: unset;
            border-bottom-style: unset;
          }
        }
      }
    }

    &--confirmation {
      &-header {
        border-radius: unset;
      }

      &-headings {
        max-width: calc(100% - 44px);
      }
    }

    &--toggle {
      &-icon {
        height: 2.6rem;

        .toggle-inner {
          width: 2.2rem !important;
          height: 2.2rem;
        }

        &.toggle-ios,
        &.toggle-md {
          &.toggle-checked {
            .toggle-inner {
              transform: translate3d(22px, 0, 0);
            }
          }
        }
      }
    }

    &--row {
      &-confirmation {
        .sb-ticket-selection--title {
          font-size: get-font-size-mobile(18);
        }
      }

      &-final {
        @include flex(flex, center, center, null, null);

        @include sb-breakpoint(null, ticket-desktop) {
          flex-flow: column nowrap;
          padding-bottom: 10px;
          max-height: unset;
        }

        .currency-left {
          &::before {
            top: 2.6rem;
            left: 10rem;
            right: unset;

            @include sb-breakpoint('ticket-desktop', null) {
              top: 1.1rem;
              left: 7.8rem;
            }
          }

          &.text-small {
            &::before {
              top: 2.5rem;

              @include sb-breakpoint('ticket-desktop', null) {
                top: 1.5rem;
              }
            }
          }
        }

        .currency-right {
          &::after {
            top: 2.6rem;
            right: 1.6rem;
            left: unset;

            @include sb-breakpoint('ticket-desktop', null) {
              top: 1.2rem;
              right: 1rem;
            }
          }

          &.text-small {
            &::after {
              top: 2.5rem;

              @include sb-breakpoint('ticket-desktop', null) {
                top: 1.5rem;
              }
            }
          }
        }
      }
    }

    &--footer {
      .sb-grid-filter {
        &--content {
          z-index: 5;

          .sb-ticket-multiselection--list {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    &--actions {
      padding-bottom: 14px;
    }

    &--button {
      &-bet,
      &-selection,
      &-settings,
      &-warning {
        @include sb-breakpoint(null, ticket-desktop) {
          width: 100%;
        }
      }

      &-selection,
      &-settings {
        @include sb-breakpoint(null, ticket-desktop) {
          margin-top: 15px;
          margin-right: 0;
        }
      }

      &-inline {
        align-items: center;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        padding-left: 0;
      }

      &-bet {
        @include sb-breakpoint('ticket-desktop', null) {
          flex-grow: unset;
          flex-basis: 50%;
          min-width: 50%;
        }
      }

      &-warning {
        @include sb-breakpoint('ticket-desktop', null) {
          flex-grow: unset;
          flex-basis: 50%;
          min-width: 50%;
        }

        .icon {
          margin-right: 2px;
          margin-left: 2px;
        }

        .text-small {
          margin-top: -0.2rem;
        }
      }

      &-settings {
        padding: 0;

        @include sb-breakpoint('ticket-desktop', null) {
          padding: 0.6rem 1rem;
        }

        .sb-ticket--button-title {
          flex: initial;
        }
      }

      &-selection {
        flex-basis: auto;

        @include sb-breakpoint('ticket-desktop', null) {
          flex-basis: 50%;
          padding: 0;
        }

        .sb-ticket--button-title {
          font-size: get-font-size-mobile(19);
        }
      }
    }
  }

  [mode='ticket-expanded-multiselection'] {
    .sb-ticket--actions {
      border: none;
    }

    .sb-grid-filter.is-open .sb-grid-filter--content {
      box-shadow: none;
    }
  }

  [mode='confirmation-expanded'] {
    .sb-ticket--actions {
      box-shadow: unset;
    }

    .sb-ticket--confirmation-header::before {
      box-shadow: unset;
    }

    .sb-ticket--row-confirmation {
      margin-top: 14px;
    }

    .sb-ticket--button-selection {
      @include sb-breakpoint(null, ticket-desktop) {
        margin-top: 15px;
        width: 100%;
      }
    }
  }

  [mode='confirmation-collapsed'] {
    padding-bottom: 180px;
    max-height: unset;

    @include sb-breakpoint('ticket-desktop', null) {
      padding-bottom: 130px;
    }

    .sb-ticket--row-confirmation {
      margin-top: 10px;
    }

    .sb-ticket--actions {
      box-shadow: unset;
    }
  }

  [mode='overask-rejected'] {
    padding-bottom: 180px;
    max-height: unset;

    @include sb-breakpoint('ticket-desktop', null) {
      padding-bottom: 130px;
    }

    .sb-ticket--row-confirmation {
      margin-top: 10px;
    }

    .sb-ticket--actions {
      box-shadow: unset;
    }
  }
}

:host.openKeyboard {
  height: calc(100vh - 308px) !important;
}

:host.hide-ticket-full {
  height: 0px !important;
}

a[disabled],
button[disabled] {
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}

ion-toggle {
  --handle-spacing: 2px;
  --handle-width: 22px;
  --handle-height: 22px;
  --track-background: #d3d3d3;
  --track-background-checked: #79c000;
  margin: 0 10px 0 0;
}

ion-toggle::part(track),
ion-toggle.toggle-checked::part(track) {
  height: 26px;
  width: 49px;
}

.rotate-icon-180 {
  transform: rotate(180deg);
}
