﻿.fade-in {
  animation: fade-in 500ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.heartbeat-animation {
  animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
  0% {
    opacity: 0.5;
  }

  25% {
    opacity: 0.75;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
