﻿//GENERAL RESETS BY PLATFORM

body.has-x-scroll {
  @media (max-width: 1024px) {
    position: absolute;
    // min-width: 1024px; This line break mobile
    height: 100vh;
    overflow-x: scroll;
  }
}

//ANDROID
.header-md {
  box-shadow: none;
}

.header-md::after {
  background-image: none !important;
}

.footer-md::before {
  background-image: none !important;
}

.list-md .item-block .item-inner {
  border-bottom: 0;
}

.footer-md {
  border-width: 0 !important;
}

//IOS

.header-ios {
  border-width: 0 0;
}

.alert-ios .alert-wrapper {
  border-radius: 5px !important;
}

.footer-ios {
  border-width: 0 !important;
}

.list-ios:not([inset]) ion-list-header {
  margin-top: 0 !important;
}

.tabsTicket .segment-button.activated {
  border-bottom: 4px solid #2d6cb1 !important;
}

.buttonCircle .button-ios {
  display: block !important;
  -webkit-flex-flow: inherit !important;
  -ms-flex-flow: inherit !important;
  flex-flow: inherit !important;
}

.button-ios.activated {
  color: get-sb-color(text-light);
  background: get-sb-color(primary);
  border-color: get-sb-color(primary);
}

.segment-ios .segment-button:active:not(.segment-activated) {
  background-color: rgba(162, 162, 162, 0.16);
}
