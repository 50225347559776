﻿button {
  &.is-bet-button {
    min-height: 3rem !important;
    padding: $inner-gutter-mobile !important;
    margin: 0;
    background: #fff;
    box-shadow: $button-shadow-mobile !important;

    .button-inner {
      @include flex(inline-flex, flex-start, center, row, nowrap);
      padding: 0;
    }

    //bet name: 'RC.Deportivo'
    .bet {
      flex: 1;
      @include font(roboto-condensed-regular, main-subtitle, bold);
      @include text-ellipsis(100);
      color: get-sb-color(primary);
      text-align: left;
      text-transform: initial !important;
    }

    //bet result '1'
    .result {
      width: auto;
      @include font(roboto-condensed-regular, main-subtitle, bold);
      color: get-sb-color(grey-900);
      text-align: right;
      text-transform: initial !important;
    }

    .horses-bet {
      flex: 1;
      @include font(roboto-condensed-bold, main-subtitle, normal);
      color: get-sb-color(grey-900);
      text-align: center;

      @include breakpoint(small-mobile) {
        font-size: 1.4rem !important;
      }
    }

    //arrow up and down
    .stateCuote {
      position: relative;
      right: 0px;
      top: 1px;
      font-size: 1.4rem !important;
      padding-left: 0 !important;
      font-size: 1.1em !important;
      font-family: 'Ionicons';

      &.max {
        &::before {
          content: '\f28b';
          color: get-sb-color(primary);
        }
      }

      &.min {
        &::before {
          content: '\f289';
          color: get-sb-color(red-regular);
        }
      }
    }

    &.selected {
      @include on-event(get-sb-color(grey-900), $on-event-amount, #fff);

      .button-inner {
        .bet,
        .result,
        .horses-bet {
          color: get-sb-color(light);
        }
      }

      .stateCuote {
        &.max {
          &::before {
            content: '\f28b';
            color: get-sb-color(light);
          }
        }
      }
    }
  }

  &.is-ticket-button {
    @include flex(inline-flex, flex-start, center, row, nowrap);
    flex: 1;
    width: 100%;
    height: 4.4rem;
    padding: 0;
    background: get-sb-color(primary);
    border-radius: $border-radius;
    color: #fff;

    &.freebetOn {
      background: get-sb-color(secondary) !important;

      .bet-change,
      .accept-bet {
        background: darken(map-get($sb-colors, secondary), $on-event-amount);
      }
    }

    &:hover:not(.disable-hover) {
      background: darken(map-get($sb-colors, primary), $on-event-amount);
    }

    .bet-change {
      @include flex(flex, flex-start, center, row, nowrap);
      width: 100%;
      height: 4.4rem;
      border-radius: $border-radius;
      background: darken(map-get($sb-colors, primary), $on-event-amount);

      ion-icon {
        margin-left: 5%;
        font-size: 2.2rem;
        margin-right: 4px;
      }

      .bet-cta {
        padding: 0 0 0 2.5%;
        align-self: center;
        font-size: 1.5rem !important;
      }
    }

    .accept-bet {
      @include flex(inline-flex, flex-start, center, row, nowrap);
      width: 65%;
      max-width: 65%;
      height: inherit;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      background: darken(map-get($sb-colors, primary), $on-event-amount);

      ion-icon {
        margin-left: 5%;
        font-size: 2.2rem;
        margin-right: 4px;
      }

      .bet-cta {
        padding: 0 0 0 5%;
        align-self: center;
      }

      .resume-bet {
        padding: 0 5% 0 0;
      }
    }

    .bet-cta {
      flex: 1;
      align-self: center;
      justify-content: flex-start;
      margin: 0;
      padding: 0 0 0 5%;
      @include font(roboto-condensed-regular, main-subtitle, normal);
      text-align: left;
    }

    .resume-bet {
      flex: 1;
      align-self: center;
      justify-content: flex-end;
      margin: 0;
      padding: 0 5% 0 0;
      @include font(roboto-condensed-light, title, normal);
      text-align: right;
    }
  }

  &.is-user-button {
    @include flex(flex, center, center, column, nowrap);
    width: 100%;
    border-radius: $border-radius;
    height: 4.5rem;
    @include font(roboto-condensed-regular, super-hero, normal);
  }

  &.reload-button {
    @include flex(flex, center, center, column, wrap);
    background: transparent;
    margin: 0;
    padding: 0 !important;

    .reload-icon {
      margin: 0 !important;
      color: get-sb-color(grey-200) !important;
      font-size: 2rem !important;
    }
  }

  .btBorder .logoutButton {
    border-color: get-sb-color(grey-900) !important;
    border: 1px solid get-sb-color(grey-900);
  }

  .btblue {
    background: get-sb-color(secondary);
  }
}

//FABS
.my-bets-fab {
  button {
    i {
      font-size: 2.4rem;
    }
  }
}

//SEGMENTS
.platform-ios {
  .segment-button {
    height: 4.9rem;
    line-height: 4.9rem;
  }

  .tabsTicket .segment-button.activated {
    @include border('bottom', 4px, solid, blue-highlight, '!important');
  }
}

//PC
.platform-core {
  button {
    /*Normalizamos hover en todas los botones de apuestas de la APP*/
    &.is-bet-button {
      min-height: 30px !important;
      max-height: 30px !important;
      padding: 0 6px !important;
      margin: 0;
      background: #fff;
      border: $border-item-pc;
      box-shadow: none !important;

      .button-inner {
        padding: 0;
      }

      &:hover:not(.disable-hover) {
        border-color: #d6d6d6;
        background: #d6d6d6;

        .button-inner,
        .result {
          color: get-sb-color(grey-900) !important;

          b {
            color: map-get($sb-colors, grey-900) !important;
          }
        }
      }

      &.selected {
        border-color: get-sb-color(grey-900);
        background: get-sb-color(grey-900);

        &:hover:not(.disable-hover) {
          border-color: get-sb-color(grey-400);
          background: get-sb-color(grey-400) !important;

          .button-inner,
          .result {
            color: get-sb-color(light) !important;
          }

          b {
            color: #fff !important;
          }
        }
      }

      .bet,
      .result,
      .horses-bet {
        @include font(roboto-condensed-regular, text, bold);
      }
    }

    &.reload-button {
      @include flex(flex, center, center, column, wrap);
      background: transparent;
      margin: 0;
      padding: 0;

      .reload-icon {
        margin: 0 !important;
        color: get-sb-color(grey-200) !important;
        font-size: 2rem !important;
      }

      &:hover:not(.disable-hover) {
        background: transparent !important;

        .reload-icon {
          color: darken(map-get($sb-colors, grey-200), $on-event-amount);
        }
      }
    }

    &.is-ticket-button {
      @include flex(inline-flex, flex-start, center, row, nowrap);
      flex: 1;
      width: 100%;
      height: 4.4rem;
      padding: 0;
      border-radius: $border-radius;
      color: #fff;
      transition: background-color 0.5s ease;

      &:hover:not(.disable-hover) {
        background: darken(map-get($sb-colors, primary), $on-event-amount);
      }

      &.freebetOn {
        background: get-sb-color(secondary) !important;

        &:hover:not(.disable-hover) {
          background: darken(map-get($sb-colors, secondary), $on-event-amount) !important;
        }

        .bet-change,
        .accept-bet {
          background: darken(map-get($sb-colors, secondary), $on-event-amount) !important;
        }
      }

      .bet-change {
        @include flex(flex, flex-start, center, row, nowrap);
        width: 100%;
        height: 4.4rem;
        border-radius: $border-radius;
        background: darken(map-get($sb-colors, primary), $on-event-amount);

        ion-icon {
          margin-left: 5%;
          font-size: 2.2rem;
          margin-right: 4px;
        }

        .bet-cta {
          padding: 0 0 0 2.5%;
          align-self: center;
          font-size: 1.5rem !important;
        }
      }

      .accept-bet {
        @include flex(inline-flex, flex-start, center, row, nowrap);
        width: 65%;
        max-width: 65%;
        height: inherit;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        background: darken(map-get($sb-colors, primary), $on-event-amount);
        float: left;

        ion-icon {
          margin-left: 5%;
          font-size: 2.2rem;
          margin-right: 4px;
        }

        .bet-cta {
          padding: 0 0 0 5%;
          align-self: center;
        }

        .resume-bet {
          padding: 0 5% 0 0;
        }
      }

      .bet-cta {
        flex: 1;
        align-self: center;
        justify-content: flex-start;
        margin: 0;
        padding: 0 0 0 5%;
        @include font(roboto-condensed-regular, title, normal);
        text-align: left;
      }

      .resume-bet {
        flex: 1;
        align-self: center;
        justify-content: flex-end;
        margin: 0;
        padding: 0 5% 0 0;
        @include font(roboto-condensed-light, title, normal);
        text-align: right;
      }

      @include breakpoint(laptop) {
        .bet-cta {
          padding: 0 0 0 5%;
          @include font(roboto-condensed-light, title, bold);
        }

        .resume-bet {
          padding: 0 5% 0 0;
          @include font(roboto-condensed-light, title, normal);
        }
      }

      @include breakpoint(tablet) {
        .bet-cta {
          padding: 0 0 0 5%;
          @include font(roboto-condensed-light, title, bold);
        }

        .resume-bet {
          padding: 0 5% 0 0;
          @include font(roboto-condensed-light, title, normal);
        }
      }

      @include browser(firefox) {
        display: inline-block;
        //flex: 0;

        .bet-cta,
        .resume-bet {
          //width: 45%;
          //max-width: 45%;
        }

        .bet-cta {
          float: left;
          flex: 0;
          padding: 12px 0 12px 5%;
        }

        .resume-bet {
          float: right;
          flex: 0;
          padding: 12px 5% 12px 0;
        }
      }
    }
  }
}

////
// Core Buttons
.cd-button {
  &--sm {
    padding: 0.9rem 1.6rem;
    background: get-sb-color(transparent);
    border-radius: get-border-radius(rounded);
  }

  &--md {
    padding: 1.3rem 1.6rem;
    background: get-sb-color(transparent);
    border-radius: get-border-radius(rounded);
  }

  &--title {
    font-family: $font-family-primary;
    font-weight: get-font-weight(medium);
    //font-size: get-font-size-mobile(sm);
    font-size: 1.8rem;
    line-height: get-line-height(xl);
  }

  &--subtitle {
    @include sb-title-md;
    font-weight: get-font-weight(regular);
    line-height: get-line-height(md);
  }
}

// Core Buttons
