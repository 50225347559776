﻿//Fallbacks por plataforma
.platform-ios10,
.platform-ios9 {
  //ticket item
  ticket-apuestas-page {
    .ticket-content {
      .checkbox {
        ion-checkbox {
          .checkbox-icon {
            margin-top: 0 !important;
          }
        }
      }

      .ticket-delete {
        flex: 0 1 4% !important;
        max-width: 12px !important;

        .deleteList {
        }
      }
    }
  }

  .tabsTicket {
    border-bottom: 0px !important;

    .segment-button,
    .segment-button.activated {
      color: #a6a6a6;
      text-transform: none;
      // font-family: 'RobotoCondensed-Regular';
      font-family: $font-family-primary;
      font-weight: get-font-weight(regular);
      font-size: 1.5rem;
      margin-left: 1rem;
      margin-right: 1.5rem;
      border-radius: 0;
      border-bottom-width: 4px;
      border-bottom-style: solid;
      border-bottom-color: rgba(0, 0, 0, 0);
      border-bottom: 4px solid transparent !important;
      display: inline-flex;
      justify-content: center;
      flex: 1;
    }
  }

  .buttonCircle {
    display: block;
  }

  codere-footer {
    display: block !important;
    position: relative;
    padding-bottom: 100px;
    background: get-sb-color(background);
  }

  ion-content.tour {
    .scroll-content {
      padding-bottom: 0px;
    }
  }

  .ticketOn > ion-page {
    .scroll-content {
      margin-bottom: 0 !important;
      padding-bottom: 60px !important;
      @include barTicket();
    }
  }
}

.platform-android4,
.platform-android3,
.platform-android2 {
  .show-menu.leftShadow {
    box-shadow: none !important;
    border-left: 1px solid #757575;
  }

  .show-menu.rightShadow {
    box-shadow: none !important;
    border-right: 1px solid #757575;
  }

  .unoxdos .button,
  .is-bet-button {
    box-shadow: none !important;
    border: 1px solid #ececec;
  }

  .destDetalle .button {
    box-shadow: none !important;
    border: 0;
  }

  .tipoApuesta .item {
    box-shadow: none !important;
    border: 0;
  }

  .detallesMercado .mercadoDestacado .opsColapsed button {
    box-shadow: none !important;
    border: 1px solid #ececec;
  }

  ion-button-effect {
    display: none;
  }

  .button {
    transition: none !important;
  }

  .now-live-detail-page .subHeader .toolbar-background {
    background-color: #000000;
  }

  .detallesEvento ion-card {
    box-shadow: none;
    /*border-left: 1px solid #dadada;
        border-right: 1px solid #dadada;*/
  }

  .barTicket {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .footer .button {
    box-shadow: none;
    border: 1px solid #bbb;
  }
}

.md {
  .modal-wrapper {
    box-shadow: none !important;
  }

  .listadoComp .bulletList img {
    -webkit-box-shadow: 0px 0px 2px 0px #767676;
    -moz-box-shadow: 0px 0px 2px 0px #767676;
    box-shadow: 0px 0px 2px 0px #767676;
  }

  .checkbox-icon {
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    border-color: #c8c7cc;
  }

  .cuota-type-page .checkbox-checked .checkbox-inner,
  .language-page .checkbox-checked .checkbox-inner {
    top: 4px;
  }

  .toolbar-title {
    font-size: 1.3rem !important;
    line-height: 4.35rem !important;
  }

  .toolbar-title {
    padding: 0;
    text-align: left;
    font-size: 1.5rem;
    line-height: 4.2rem;
  }

  .button-fab {
    box-shadow: none;
  }

  ion-title {
    padding: 0px 0px 1px 0px;
    width: 68%;
    max-width: 10rem;
  }

  .button {
    box-shadow: none;
  }

  .contactButton button {
    height: 4rem;
  }

  .loginOps {
    padding-bottom: 5px;
  }

  ion-card .button {
    width: 100%;
    height: 1.8em;
  }

  ion-card {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .tipoApuesta ion-label {
    margin: 8px;
    margin-bottom: 2px;
    margin-top: 2px;
  }

  .toolbar .back-button {
    margin: 0 1px 0 2px !important;
  }

  .item-label-floating .text-input {
    margin-top: 0px;
  }

  //**+** Remplazado por .md .in-toolbar i
  // .toolbar i {
  //   margin-top: 0;
  //   font-size: 2.4rem;
  // }

  //**+** Chanca estilos de los botones en el toolbar
  // .md .in-toolbar i{s
  //   margin-top: 0;
  //   font-size: 2.4rem;
  // }

  .bar-button-menutoggle {
    i {
      width: 2rem;
      overflow: hidden;
    }
  }

  .toolbar-content i:last-child {
    margin-top: 0px;
  }

  .toolbar .bar-button-menutoggle {
    /* padding-top: 7px;*/
    /*margin-top: -9px;*/
  }
  /*.searchbar-search-icon {
        top: 7px;
        }*/
  .item-input::after {
    border: 0;
  }

  .toolbar .align-left {
    top: 4px;
    position: relative;
  }
}

.item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus) .item-inner {
  box-shadow: none !important;
}

.item.item-md .checkbox-md {
  margin-right: 0px !important;
}

.item-ios.item-block .item-inner {
  border-bottom: 0 !important;
}

.ios {
  // * { letter-spacing: -0.05rem; }

  .listadoComp .bulletList img {
    -webkit-box-shadow: 0px 0px 2px 0px #767676;
    -moz-box-shadow: 0px 0px 2px 0px #767676;
    box-shadow: 0px 0px 2px 0px #767676;
  }

  .language-page .checkbox-checked .checkbox-inner,
  .cuota-type-page .checkbox-checked .checkbox-inner {
    border-right-width: 2px;
    border-bottom-width: 2px;
  }

  .toolbar-title {
    font-size: 1.3rem !important;
  }

  .toolbar .back-button {
    margin: 0 1px 0 2px !important;
  }

  .segment-button {
    padding: 0 6px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.1);
    height: 4.2rem;
    line-height: 4rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 500;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 0;
    opacity: 0.7;
    color: get-sb-color(primary);
    background-color: transparent;
    -webkit-transition: 100ms all linear;
    transition: 100ms all linear;
    max-width: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .toolbar-title {
    display: block;
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    margin-left: 0px;
    line-height: 4.2rem;
  }

  ion-title {
    padding: 0px 0px 1px 40px;
    width: 68%;
    max-width: 14rem;
  }

  .segment-button {
    opacity: 1 !important;
  }

  .flag img {
    margin: 1.5rem !important;
  }

  .btBorder {
    padding: 0 15px;
    font-size: 1.2rem;
    letter-spacing: 1rem !important;
  }

  .loginOps {
    padding-bottom: 0px;
    padding-top: 0;
  }

  .toolbar-title {
    line-height: 5rem;
    margin-top: 0px;
  }

  .opsColapsed .row {
  }

  .toolbar {
    min-height: 51px;
  }

  ion-card .button {
    width: 100%;
    height: 1.8em;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }

  ion-card {
    box-shadow: 0 1px 2px rgb(230, 230, 230);
  }

  .tipoApuesta ion-label {
    margin: 8px;
    margin-bottom: 2px;
    margin-top: 2px;
  }

  .input-has-focus ion-label[floating] {
    margin-top: 4px;
    // font-family: 'Roboto';
    font-family: $font-family-primary;
  }

  .input-has-focus {
    border: 1px solid get-sb-color(primary) !important;
  }

  .input-has-value ion-label[floating] {
    margin-top: 4px;
    // font-family: 'Roboto';
    font-family: $font-family-primary;
  }

  .item-input ion-input {
    margin-top: -7px;
    font-size: 1.8rem;
  }

  .item-label-floating .text-input {
    /*margin-top: 21px !important;*/
  }

  .toolbar i {
    margin-top: 0px;
    font-size: 2.4rem;
  }

  .bar-button-menutoggle {
    i {
      width: 2rem;
      overflow: hidden;
    }
  }

  .toolbar-content i:last-child {
    margin-top: 0px;
  }

  .logo {
    margin-top: -3px;
  }

  .header-ios .toolbar-ios:last-child .toolbar-background-ios {
    border-width: 0;
  }
}

.platform-core {
  .list-md + ion-list ion-list-header {
    margin: 0px !important;
  }
}

//PLATFORM CORE FALLBACKS GENERALES BY BROWSER
.microsoft-browser,
.mozilla-browser,
.apple-browser {
  .platform-core {
    .alert-message {
      overflow: hidden;
    }

    ion-modal {
      .scroll-content {
        overflow-y: hidden;
      }
    }
  }
}

.mozilla-browser {
  .platform-core button.is-ticket-button .bet-change .bet-cta {
    width: 100%;
    display: block;
    flex: auto;
  }
}
