/// Alerts

.sb-alert--title,
.sb-alert--text {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);
  text-transform: initial !important;
  text-align: center !important;
  border: none !important;
}

/// Navbar live

.sb-live-navbar--breadcrumbs {
  padding-bottom: $sb-line-height-correction;
  padding-left: $margin--base;
}

.sb-live-navbar--back-button + .sb-live-navbar--breadcrumbs {
  padding-left: 0;
}

.sb-live-navbar--link {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
}

.sb-live-navbar--label {
  flex: initial;
  @include sb-title-md;
  font-weight: get-font-weight(medium);
}

/// Navbar live

/// Back button

.sb-back-button {
  @include flex(flex, center, center, row, nowrap);
  width: 4.4rem;
  height: 4.4rem;
  border-right-width: $border-width--sm;
  border-right-style: solid;
  border-right-color: rgba($border-color--sb-back-button, 0.5);

  &::before {
    font-size: 1.6rem;
  }
}

event-page .sb-back-button {
  min-width: 6rem;
}

.sb-back--title {
  @include sb-title-xl($margin: 0 1.8rem);
  font-weight: get-font-weight(medium);
}

/// Back button

/// Navbar

.sb-navbar--title {
  @include sb-title-sm;
}

.sb-navbar-item--icon {
  margin: 0;
  font-family: $font-family-secondary;
  font-size: 2.8rem;
  font-weight: get-font-weight(regular);
  line-height: get-line-height(xs);
}

.sb-navbar-item--subtitle {
  @include sb-title-sm($margin: 0.8rem 0 0 0);
  font-weight: get-font-weight(regular);
  line-height: get-line-height(md);
  max-width: 86%;
  @include sb-text-ellipsis;
}

[theme='lobby'] {
  .sb-navbar-item--subtitle {
    // @include flex(flex, null, center, null, null);
    // margin: 0;
    margin: 0.8rem 0 0 0;
    width: auto;
    min-height: 2rem;
    line-height: 1;
    text-align: center;
    max-width: unset;
    @include sb-reset-ellipsis;
  }
}

.platform-core {
  [theme='lobby'] {
    &.sb-navbar {
      background: get-sb-color(transparent);
    }

    // .sb-navbar-item--icon {
    //   font-size: 2.8rem;
    // }

    // .sb-navbar-item--subtitle {
    //   margin-top: 2px;
    //   min-height: unset;
    //   font-weight: get-font-weight(bold);
    //   font-size: 1.5rem;
    //   text-transform: uppercase;
    // }
  }
}

/// Navbar

/// Navbar calendar

.sb-calendar-navbar--day {
  @include sb-title-sm;
  font-weight: get-font-weight(regular);
  line-height: get-line-height(md);
  text-transform: uppercase;
}

.sb-calendar-navbar--date {
  @include sb-title-sm($margin: 0.3rem 0 0 0);
  font-weight: get-font-weight(regular);
  line-height: get-line-height(md);
}

.sb-calendar--empty-state {
  padding: 3rem;
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(xl);
  text-align: center;
  background-color: #adb2b5;
  background-color: #adb2b5;
}

/// Navbar calendar

/// Scoreboard

/// Prematch scoreboard
.sb-prematch-scoreboard--title {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(xl);
  max-width: 95%;
  @include sb-text-ellipsis;

  .sb-prematch-scoreboard--item:last-child & {
    margin-left: auto;
  }
}

.sb-prematch-scoreboard--subtitle {
  @include sb-title-lg;
  font-weight: get-font-weight(regular);
  line-height: get-line-height(lg);
  max-width: 95%;
  color: get-sb-color(grey-400);
  @include sb-text-ellipsis;

  .sb-prematch-scoreboard--item:last-child & {
    margin-left: auto;
  }
}

.sb-prematch-scoreboard--number {
  @include sb-title-lg;
  font-weight: get-font-weight(regular);
  line-height: get-line-height(lg);
}

/// Regular scoreboard

// .sb-live-scoreboard-regular--title {
//   @include sb-title-sm;
//   max-width: 95%;
//   font-weight: get-font-weight(regular);
//   line-height: get-line-height(sm);
//   @include sb-text-ellipsis;

// }

// .sb-live-scoreboard-regular--subtitle {
//   @include sb-title-md;
//   font-weight: get-font-weight(regular);
//   line-height: get-line-height(md);
//   @include sb-text-ellipsis;
// }

// .sb-live-scoreboard--number {
//   @include sb-title-xl;
//   font-weight: get-font-weight(medium);
//   line-height: get-line-height(xl);

// }

/// Sets scoreboard

.sb-live-scoreboard--title {
  @include sb-title-xl;
  font-weight: get-font-weight(regular);
  line-height: get-line-height(md);
  max-width: 95%;
  @include sb-text-ellipsis;

  // .sb-live-scoreboard--score & {
  //   padding-bottom: $sb-line-height-correction;
  // }

  sb-live-scoreboard-sets & {
    margin-bottom: 0.4rem;
  }

  sb-live-scoreboard-baseball & {
    margin-bottom: 0.4rem;
  }
}

.sb-live-scoreboard--subtitle {
  @include sb-title-md;
  max-width: 100%;
  font-weight: get-font-weight(regular);
  line-height: get-line-height(md);
  color: get-sb-color(background-light);

  sb-live-scoreboard-regular & {
    @include sb-text-ellipsis;
  }

  // sb-live-scoreboard-sets & {
  //   padding-bottom: 0.1rem;
  // }

  sb-live-scoreboard-baseball & {
    padding-bottom: 0.2rem;
    color: get-sb-color(background-light);
  }
}

.sb-live-scoreboard--number {
  @include sb-title-xl;
  font-weight: get-font-weight(regular);
  line-height: get-line-height(md);
  // @include sb-text-ellipsis;

  sb-live-scoreboard-regular & {
    font-weight: get-font-weight(medium);
  }

  .sb-live-scoreboard--score & {
    //padding-bottom: $sb-line-height-correction;
    padding-top: $sb-line-height-correction;
  }

  sb-live-scoreboard-sets & {
    margin-bottom: 0.4rem;
  }

  sb-live-scoreboard-baseball & {
    margin-bottom: 0.4rem;
  }
}

.sb-live-scoreboard--legend {
  font-size: 1.4rem;
  line-height: 0.9;

  sb-live-scoreboard-sets & {
    // margin-bottom: 0.9rem;
    margin-bottom: 1.1rem;

    &.icon-soccer {
      font-size: 1.2rem;
      margin-bottom: 1.2rem;
    }
  }

  sb-live-scoreboard-sets .is-column-attack & {
    margin-bottom: 0.6rem;

    &:not(.color-light) {
      color: transparent;
    }
  }

  sb-live-scoreboard-baseball & {
    margin-bottom: 0.6rem;
  }
}

/// Baseball scoreboard

// .sb-live-scoreboard-baseball--title {
//   @include sb-title-xl;
//   margin-bottom: 0.6rem;
//   max-width: 100%;
//   font-weight: get-font-weight(regular);
//   line-height: get-line-height(xs);
// }

// .sb-live-scoreboard-baseball--subtitle {
//   @include sb-title-md;
//   padding-bottom: 0.2rem;
//   max-width: 100%;
//   font-weight: get-font-weight(regular);
//   line-height: get-line-height(md);
//   color: get-sb-color(background-light);
// }

// .sb-live-scoreboard-baseball--number {
//   @include sb-title-xl;
//   margin-bottom: 0.6rem;
//   font-weight: get-font-weight(regular);
//   line-height: get-line-height(xs);
// }

/// Scoreboard

/// Filters

.sb-filter--title {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(xl);
}

.sb-filter-mostbet--title {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
}

/// Filters

/// Lists

// .sb-list--title {
//   @include sb-title-xl;
//   font-weight: get-font-weight(medium);
//   line-height: get-line-height(xl);
// }

// .sb-list--number {
//   @include sb-title-xl;
//   font-weight: get-font-weight(medium);
//   line-height: get-line-height(xl);
// }

/// Lists

/// Streaming

.sb-stream-market--title {
  @include sb-title-xl;
  font-weight: get-font-weight(bold);
  line-height: get-line-height(xl);
}

/// Streaming

/// Grid

/// Grid home
.sb-grid-header--onlive-title,
.sb-grid-header--more-bet-title,
.sb-grid-header--sport-title {
  flex: 1;
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(xl);
}

.sb-grid-header--sport-icon {
  margin-right: $margin--base;
}

.sb-grid-header--bets-counter {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(xl);

  .icon-arrow-right:before {
    font-size: get-font-size-mobile(sm);
  }
}

/// Grid filter
.sb-grid-filter--title {
  @include sb-title-xl($margin: 0 0 0 $margin--base);
  font-weight: get-font-weight(bold);
  line-height: get-line-height(md);
  font-size: 14px;
}

.sb-grid-filter--subtitle {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);
}

/// Grid header
.sb-grid-header--date {
  @include offset($top: 0, $bottom: 0, $left: #{$margin--base});
  @include flex-row(flex-start, center, null);
  @include sb-title-md;
  font-weight: get-font-weight(regular);
  line-height: 1.8;
  @include sb-text-ellipsis;
}

.sb-grid-header--winner {
  @include offset($top: 0, $right: 70%, $bottom: 0, $left: 0);
  @include flex-row(center, center, null);
  @include sb-title-lg;
  font-weight: get-font-weight(medium);
  line-height: 1.8;
  @include sb-text-ellipsis;
}

.sb-grid-header--title {
  position: absolute;
  @include sb-title-lg;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(lg);
  max-width: 95%;
  @include sb-text-ellipsis;
}

/// Grid item
.sb-grid-item--title {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(xl);
  @include sb-text-ellipsis;

  @include sb-breakpoint(null, xs) {
    letter-spacing: $sb-letter-spacing--sm;
  }

  .sb-grid-content--single & {
    margin-bottom: 0;
  }

  .sb-grid-content--thumbnail & {
    margin-bottom: 0.1rem;
  }

  .sb-grid-content--expanded.has-special-bets & {
    line-height: get-line-height(lg);
    white-space: unset;
    overflow: unset;
  }
}

.sb-grid-item--subtitle {
  position: relative;
  @include sb-title-md;
  font-weight: get-font-weight(regular);
  line-height: 1.8;
  @include sb-text-ellipsis;

  &.has-streaming::after {
    @include pseudo($content: '\e963', $top: 0);
    padding-left: 0.5rem;
    font-family: $font-family-secondary;
    font-size: get-font-size-mobile(lg);
    color: get-sb-color(secondary);
  }

  .sb-grid-content--thumbnail & {
    line-height: get-line-height(md);
  }
}

.has-streaming::after {
  @include pseudo($content: '\e963', $position: relative, $top: 0);
  // padding-left: 0.5rem;
  font-family: $font-family-secondary;
  font-size: get-font-size-mobile(lg);
  color: get-sb-color(secondary);
}

/// Grid score
.sb-grid-item--number {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(xl);
}

/// Grid races
.sb-grid-item--icon-number {
  @include sb-title-md;
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;
  min-width: 1.4rem;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(xs);
  text-align: center;
}

/// Grid button
.sb-button--bet-text {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);
}

.sb-button--title {
  @include sb-title-lg(0 auto);
  font-weight: get-font-weight(regular);
  line-height: get-line-height(xl);
  max-width: 95%;
  @include sb-text-ellipsis;
}

.sb-button--subtitle {
  @include sb-title-lg(0 auto);
  font-weight: get-font-weight(bold);
  line-height: get-line-height(xl);

  .sb-grid-item--race-bets & {
    margin: 0 auto;
    max-width: 95%;
    @include sb-text-ellipsis;
  }

  .has-four-buttons & {
    max-width: 100%;
  }
}

/// Grid

.sb-item-container-margin-bot {
  margin-bottom: 1px;
}

.sb-item-container-margin-bot section {
  margin-bottom: 1px;
}

/// Dropdowns

.sb-dropdown--title {
  flex: 1;
  @include sb-title-md;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);

  .sb-grid-filter & {
    font-weight: get-font-weight(bold);
  }
}

.sb-prematch-item--title {
  flex: 1;
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);

  .sb-grid-filter & {
    font-weight: get-font-weight(bold);
  }
}

.sb-prematch-item--subtitle {
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(lg);
}

/// Dropdowns

/// Ticket

.sb-ticket--empty-state {
  @include sb-title-xl;
  font-weight: get-font-weight(regular);
  line-height: get-line-height(xl);
}

.sb-ticket--modal-title {
  margin: 0;
  margin-bottom: $margin--base;
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(18);
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);
  width: 100%;
}

.sb-ticket--modal-subtitle {
  margin: 0;
  margin-bottom: $margin--base;
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(16);
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);
  width: 100%;
}

.sb-ticket--banner-title {
  // font-family: $font-family-primary;
  // font-size: get-font-size-mobile(19);
  @include sb-title-xl;
  font-weight: get-font-weight(bold);
  line-height: get-line-height(sm);

  .text-big {
    font-size: 2.9rem;
  }
}

.sb-ticket--banner-subtitle {
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(xl);
  font-weight: get-font-weight(regular);
  line-height: 1.5;

  .text-medium {
    font-weight: get-font-weight(medium);
  }
}

.sb-ticket-selection--title,
.sb-ticket-selection-betbuilder--title {
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(15);
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);
  max-width: 50vw;
  @include sb-text-ellipsis;
  .sb-ticket--row-confirmation & {
    font-size: get-font-size-mobile(16);
    font-weight: get-font-weight(regular);
  }

  .sb-ticket--confirmation-headings & {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.sb-ticket-selection--subtitle,
.sb-ticket-selection-betbuilder--subtitle {
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(xl);
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);
  max-width: 100%;
  overflow: hidden;
  @include sb-text-ellipsis;

  & + .sb-ticket-selection--subtitle {
    font-weight: get-font-weight(regular);
  }

  .sb-ticket--row-confirmation & {
    font-size: get-font-size-mobile(16);
    font-weight: get-font-weight(regular);
  }

  .sb-ticket--confirmation-headings & {
    font-size: get-font-size-mobile(lg);
    font-weight: get-font-weight(medium);
  }
}

.sb-ticket-selection--subtitle-profit {
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(xl);
  font-weight: get-font-weight(medium);
  line-height: 1.2;
  overflow: hidden;
  @include sb-text-ellipsis;
  align-self: end;

  & + .sb-ticket-selection--subtitle2 {
    font-weight: get-font-weight(regular);
  }

  .sb-ticket--row-confirmation2 & {
    font-size: get-font-size-mobile(16);
    font-weight: get-font-weight(regular);
  }

  .sb-ticket--confirmation-headings & {
    font-size: get-font-size-mobile(lg);
    font-weight: get-font-weight(medium);
  }
}

.sb-ticket-selection--overask {
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(15);
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);
  max-width: 100%;

  .sb-ticket--row-confirmation & {
    font-size: get-font-size-mobile(16);
    font-weight: get-font-weight(regular);
  }

  .sb-ticket--confirmation-headings & {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.sb-ticket-multiselection--title {
  @include sb-title-xl;
  font-weight: get-font-weight(bold);
  line-height: get-line-height(sm);
  max-width: 100%;
  @include sb-text-ellipsis;
}

.sb-ticket-multiselection--list {
  margin: 0;
  padding: 0;
  z-index: 9999;
}

.sb-grid-filter--item {
  padding: 1rem;
  height: 4.4rem;
  display: flex;
  align-items: center;
}

.sb-ticket--toggle-title {
  @include sb-title-xl(0 10px 0 0);
  font-size: get-font-size-mobile(16);
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);

  @include sb-breakpoint(null, ticket-toggle) {
    margin-right: 6px;
    font-size: get-font-size-mobile(xl);
  }

  @include sb-breakpoint(null, ticket-toggle-title) {
    font-size: get-font-size-mobile(lg);
  }

  &:last-child {
    margin-right: 0;
  }

  .sb-ticket--header & {
    font-size: get-font-size-mobile(xl);
  }
}

.sb-ticket--resume-bullet,
.sb-ticket--resume-title,
.sb-tm-small-footer--resume-title {
  // @include sb-title-xl;
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(18);
  font-weight: get-font-weight(medium);
  line-height: get-line-height(md);
}

.sb-ticket--button-title {
  display: block;
  // @include sb-title-xl;
  font-family: $font-family-primary;
  // font-size: get-font-size-mobile(19);
  font-size: get-font-size-mobile(16);
  font-weight: get-font-weight(medium);
  line-height: get-line-height(sm);

  @include sb-breakpoint(null, ticket-toggle-title) {
    font-size: get-font-size-mobile(15);

    .sb-ticket-selection--button & {
      font-size: get-font-size-mobile(19);
    }

    .text-small &,
    &.text-small {
      font-size: get-font-size-desktop(sm);
    }
  }

  .sb-ticket--button-settings &.text-small {
    margin-bottom: 0;
    font-size: get-font-size-mobile(sm);
  }

  .sb-ticket--header-button &,
  .sb-ticket--delete & {
    // margin-bottom: 1px;
    font-size: get-font-size-mobile(lg);
  }

  .sb-ticket--button-settings & {
    margin-bottom: 0.2rem;
  }

  .sb-ticket--button-modal &,
  .sb-ticket--button-stake &,
  .sb-ticket--button-bet & {
    font-size: get-font-size-mobile(18);
  }

  .text-small .sb-ticket--button-stake & {
    font-size: get-font-size-mobile(15);
  }

  .text-small & {
    // margin-top: 3px;
    // font-weight: get-font-weight(regular);
    font-size: get-font-size-mobile(md);
  }

  & + .sb-ticket--button-title {
    &.text-small {
      margin-top: 1px;
      font-size: get-font-size-mobile(sm);
    }
  }

  .sb-ticket--button-selection & {
    font-size: get-font-size-mobile(xl);
  }

  .sb-ticket--button-warning & {
    &.text-small {
      flex: unset;
      font-weight: get-font-weight(regular);
      font-size: get-font-size-mobile(md);
    }
  }
}

.sb-ticket--stake-title {
  display: block;
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(16);
  font-weight: get-font-weight(medium);
  line-height: get-line-height(sm);

  .text-small & {
    font-size: get-font-size-mobile(md);
  }
}

.platform-mobile {
  .sb-ticket-selection--button {
    letter-spacing: -0.5px;
  }
}

.platform-core {
  .sb-ticket--delete {
    .sb-ticket--button-title {
      font-size: get-font-size-mobile(lg);
      text-transform: initial;
    }
  }

  .sb-ticket--button-warning {
    .sb-ticket--button-title {
      flex: initial;
    }
  }

  // .sb-ticket--button-title {
  //   font-size: get-font-size-mobile(19);
  // }
}

/// Ticket

/// Bet slip footer

.bet-slip-footer--title {
  @include sb-title-xl;
  font-weight: get-font-weight(bold);
  line-height: get-line-height(md);
}

.bet-slip-footer--text {
  @include sb-title-xl;
  font-weight: get-font-weight(bold);
  line-height: get-line-height(md);
}
/// Bet slip footer

// Empty State
.sb-empty-state {
  padding: 3rem;
  @include sb-title-xl;
  font-weight: get-font-weight(medium);
  line-height: get-line-height(xl);
  text-align: center;
  height: 100%;
  width: 100%;
}

.platform-core {
  /// Dropdowns
  .sb-dropdown-desktop .sb-grid-filter--title,
  .sb-dropdown-desktop .sb-prematch-item--title {
    font-weight: get-font-weight(medium);
  }

  .sb-grid-header--desktop .sb-dropdown--title {
    margin-left: 1.8rem;
  }

  /// Grid
  .sb-filter--title {
    font-size: get-font-size-desktop(xxl);
  }

  .cashier .sb-filter--title {
    font-size: 12px !important;
  }

  /// Navbar
  // .sb-back--title {
  //   font-size: get-font-size-desktop(xl);
  // }

  /// Calendar
  .sb-calendar--empty-state {
    padding: 5rem;
  }

  /// Scoreboard
  sb-live-scoreboard-sets:not(.sb-grid-layout--item) .sb-live-scoreboard--title {
    max-width: unset;
    @include sb-reset-ellipsis;
  }

  sb-live-scoreboard-baseball:not(.sb-grid-layout--item) .sb-live-scoreboard--title {
    max-width: unset;
    @include sb-reset-ellipsis;
  }

  /// Filter

  .sb-filter-mostbet--title {
    font-size: get-font-size-desktop(xl);
  }

  .sb-filter--ticket {
    font-size: get-font-size-desktop(14);
  }

  .sb-grid-filter--title {
    max-width: calc(100% - 50px);
    font-size: get-font-size-desktop(xl);
  }

  .sb-prematch-item--title {
    font-size: get-font-size-desktop(xl);
  }

  /// Grid  /// Grid home
  .sb-grid-header--onlive-title,
  .sb-grid-header--more-bet-title,
  .sb-grid-header--sport-title {
    font-size: get-font-size-desktop(xl);
  }

  .sb-grid-item .sb-grid-header--bets-counter {
    min-width: 5rem;
    text-align: center;
  }

  .sb-grid-header--bets-counter {
    font-size: get-font-size-desktop(xl);
  }

  .sb-grid-item--title {
    font-size: get-font-size-desktop(xl);
  }

  .sb-grid-item--subtitle {
    margin-top: 1px;
    font-size: get-font-size-desktop(lg);
    line-height: get-line-height(xl);
  }

  .sb-grid-item--subtitle.has-streaming::after {
    top: 1px;
    font-size: get-font-size-desktop(lg);
  }

  .sb-grid-item--number {
    font-size: get-font-size-desktop(xl);
  }

  .sb-button--bet {
    font-size: get-font-size-desktop(xl);
  }

  .sb-button--title {
    font-size: get-font-size-desktop(xl);
    line-height: get-line-height(lg);
  }

  .sb-button--subtitle {
    font-size: get-font-size-desktop(xl);
    line-height: get-line-height(md);
  }

  /// Grid races
  .sb-grid-item--icon-number {
    margin-top: 0;
    margin-right: 1rem;
    margin-bottom: 0;
    padding-bottom: 0.4rem;
    font-weight: get-font-weight(medium);
  }

  /// Dropdowns
  .sb-dropdown--title {
    font-size: get-font-size-desktop(xl);
  }
}
