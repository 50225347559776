.sb-ticket-selection--content-right,
.sb-ticket-betbuilder--content-right {
  sb-ticket-stake {
    .sb-ticket-stake--label {
      display: none;
    }

    .sb-ticket--button-stake {
      text-overflow: ellipsis;
      border-radius: 50px;
      border-style: solid;

      &.is-selected {
        background: get-sb-color(light);
        border: $border-width--sm solid #{get-sb-color(primary)};
        opacity: 1 !important;
      }

      &:focus {
        color: #fff;
        background: #fff;
        border: 3px solid #79c000;
      }

      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      margin-right: 0;
      margin-left: auto;
      padding-top: 0.7rem;
      padding-right: $margin--base;
      padding-bottom: 0.8rem;
      padding-left: 1.6rem;
      min-width: 9rem;
      max-width: 9rem;
      min-height: 3.4rem;
      max-height: 3.4rem;
      text-align: center;
      // text-overflow: ellipsis;

      & + .sb-ticket--stake-title {
        position: absolute;
        top: 1.2rem;
        min-width: 6rem;
        max-width: 6rem;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        pointer-events: none;
      }
    }

    &.text-small .sb-ticket--button-stake + .sb-ticket--stake-title {
      top: 1.6rem;
    }

    &.currency-right {
      .sb-ticket--button-stake + .sb-ticket--stake-title {
        right: 2rem;
      }

      &.text-small {
        .sb-ticket--button-stake + .sb-ticket--stake-title {
          right: 2.1rem;
        }
      }
      &::after {
        content: '€';
        position: absolute;
        z-index: 1;
        top: 1rem;
        right: 15%;
        font-family: Roboto;
        font-weight: 700;
        font-size: 1.9rem;
        color: #333f48;
      }
    }

    &.currency-left {
      .sb-ticket--button-stake + .sb-ticket--stake-title {
        right: 0.9rem;
      }
      &.text-small {
        .sb-ticket--button-stake + .sb-ticket--stake-title {
          right: 1rem;
        }
      }
      &::after {
        content: '$';
        position: absolute;
        z-index: 1;
        top: 0.65rem;
        right: 7.2rem;
        font-family: Roboto;
        font-weight: 700;
        font-size: 1.9rem;
        color: #333f48;
      }
    }
  }
}
ticket {
  .sb-ticket--button-stake {
    @extend .sb-ticket--button;
    flex-basis: 35%;
    text-align: right;
    text-overflow: ellipsis;

    @include sb-breakpoint(sm, null) {
      flex-basis: 40%;
    }

    &:focus,
    .activated {
      color: #fff;
      background: #fff;
      border: 3px solid #79c000;
    }
  }
}
.platform-core {
  sb-ticket-selection {
    .sb-ticket-selection {
      &--content-right {
        sb-ticket-stake {
          @include sb-breakpoint('ticket-desktop', null) {
            padding-right: unset;
          }
        }
      }
    }
  }
  ticket {
    .sb-ticket {
      &--row {
        &-final {
          .sb-ticket--button-stake {
            text-overflow: ellipsis;
            border-radius: 50px;
            border-style: solid;
            -webkit-flex-basis: unset;
            -ms-flex-preferred-size: unset;
            flex-basis: unset;
            margin-top: 15px;
            margin-bottom: 1rem;
            padding-top: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            padding-left: 1rem;
            width: 100%;
            line-height: 1;
            text-align: right;

            @include sb-breakpoint('ticket-desktop', null) {
              margin-bottom: 0.5rem;
            }

            & + .sb-ticket--stake-title {
              position: absolute;
              top: 2.9rem;
              right: 2rem;
              min-width: 10rem;
              max-width: 10rem;
              text-align: right;
              white-space: nowrap;
              overflow: hidden;
              pointer-events: none;
              @include sb-breakpoint('ticket-desktop', null) {
                top: 1.45rem;
                min-width: 8rem;
                max-width: 8rem;
              }
            }
          }
          .sb-ticket--button-stake[disabled] {
            cursor: pointer !important;
            opacity: 1 !important;
            text-align: center !important;
            margin-bottom: 1rem;

            @include sb-breakpoint('ticket-desktop', null) {
              margin-bottom: 0.5rem;
            }
          }
          .currency-left {
            .sb-ticket--button-stake {
              opacity: 1 !important;
            }
          }
          .currency-right {
            .sb-ticket--button-stake {
              @include sb-breakpoint('ticket-desktop', null) {
                padding-right: 2.2rem;
                min-width: 100%;
                min-height: 4.4rem;
                max-height: 4.4rem;
                margin-top: 0px !important;
              }
              & + .sb-ticket--stake-title {
                right: 3.5rem;
              }
            }
          }
        }
      }
      &--button {
        &-stake {
          margin-right: 0;
          margin-left: auto;
          padding-top: 0.7rem;
          padding-right: 1rem;
          padding-bottom: 0.8rem;
          padding-left: 1.6rem;
          text-align: center;
          &.has-errors {
            margin-right: 10px;
          }
        }
        &-stake {
          width: 100%;
          line-height: get-line-height(sm);

          @include sb-breakpoint('ticket-desktop', null) {
            margin-top: 0px !important;
          }
        }
      }
    }
  }

  [mode='locked'] {
    &.sb-ticket--button-stake {
      padding: 0;

      @include sb-breakpoint('ticket-desktop', null) {
        margin-right: $margin--base;
        flex-grow: 1;
        flex-basis: 50%;
      }

      .sb-ticket--button-title {
        text-align: center;
      }
    }
  }
}

.platform-mobile {
  /// Ticket animation
  .sb-ticket {
    &--button-stake {
      margin-right: 1rem;
    }

    &--row-final {
      .sb-ticket--button-stake {
        @include flex(flex, center, center, null, null);
        opacity: 1 !important;

        .sb-ticket--button-title {
          display: inline-block;
        }
      }
    }
  }
}
