﻿/// Media Query

/// Old version
@mixin breakpoint($point) {
  @if $point==small-mobile {
    @media (max-width: 320px) {
      @content;
    }
  } @else if $point==medium-mobile {
    @media (min-width: 321px) and (max-width: 360px) {
      @content;
    }
  } @else if $point==tablet {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point==laptop {
    @media (min-width: 1025px) and (max-width: 1280px) {
      @content;
    }
  } @else if $point==desktop {
    @media (min-width: 1281px) {
      @content;
    }
  }
}

/// Sportbook

@mixin sb-ion-grid-n-col-padding($padding) {
  ion-grid {
    padding: unquote($padding + 'px') 0 0 unquote($padding + 'px');
    ion-col {
      padding: 0 unquote($padding + 'px') unquote($padding + 'px') 0;
    }
  }
}

// @mixin sb-breakpoint($size, $type: min) {

//   @if $size == 'xs' {
//     @media (#{$type}-width: $breakpoint--xs) {
//       @content;
//     }
//   } @else if $size == 'sm' {
//     @media (#{$type}-width: $breakpoint--sm) {
//       @content;
//     }
//   } @else if $size == 'md' {
//     @media (#{$type}-width: $breakpoint--md) {
//       @content;
//     }
//   } @else if $size == 'lg' {
//     @media (#{$type}-width: $breakpoint--lg) {
//       @content;
//     }
//   } @else if $size == 'xl' {
//     @media (#{$type}-width: $breakpoint--xl) {
//       @content;
//     }
// 	} @else  {
//     @media (#{$type}-width: $size) {
//       @content;
// 		}
// 	}

// }

@mixin sb-breakpoint($min, $max) {
  @if $max==null {
    @media (min-width: map-get($breakpoints, $min)) {
      @content;
    }
  } @else if $min==null {
    @media (max-width: map-get($breakpoints, $max)) {
      @content;
    }
  } @else if $min !=null and $max !=null {
    @media (min-width: map-get($breakpoints, $min)) and (max-width: map-get($breakpoints, $max)) {
      @content;
    }
  }
}

/// Media Query

/// Position

/// Sportbook
@mixin offset($position: absolute, $z-index: null, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  z-index: map-get($z-index-map, $z-index);
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/// Sportbook
@mixin pseudo($content: '', $position: absolute, $z-index: null, $top: null, $right: null, $bottom: null, $left: null) {
  content: $content;
  position: $position;
  z-index: map-get($z-index-map, $z-index);
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/// Sportbook
@mixin flex($flex, $x-axis, $y-axis, $direction, $wrap) {
  display: $flex;
  justify-content: $x-axis;
  align-items: $y-axis;
  flex-flow: $direction $wrap;
}

/*@mixin inline-flex($wrap, $x-axis: flex-start, $y-axis: center) {
		display: inline-flex;
		justify-content: $x-axis;
		align-items: $y-axis;
		flex-flow: row $wrap;
	}*/

/// Position

/// Display

/// Sportbook
@mixin flex-row($x-axis: center, $y-axis: center, $wrap: null) {
  display: flex;
  justify-content: $x-axis;
  align-items: $y-axis;
  flex-wrap: $wrap;
}

/// Sportbook
@mixin flex-column($direction: column, $x-axis: center, $y-axis: null, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  align-items: $x-axis;
  justify-content: $y-axis;
  flex-wrap: $wrap;
}

/// Display

/// Font

/// Old version
/// Mixin para fuentes: se especifica familia, tamaño y peso de los maps de fuentes en ./variables
@mixin font($family, $size, $weight) {
  font-family: map-get($font-family, $family) !important;
  font-size: map-get($font-size, $size) !important;
  font-weight: map-get($font-weight, $weight) !important;
}

/// Old version
///Text Ellipsis especificando truncate de la caja que contiene el texto
@mixin text-ellipsis($width) {
  text-overflow: ellipsis;
  width: unquote($width + '%');
  white-space: nowrap;
  overflow: hidden;
}

/// Sportbook
@mixin sb-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin sb-reset-ellipsis {
  text-overflow: unset;
  white-space: unset;
  overflow: unset;
}

/// Old version
///Rompe el texto en líneas
@mixin text-break() {
  overflow: auto;
  word-break: break-word;
  white-space: normal;
}

/// Font

/// Text

/// Sportbook
@mixin sb-title-sm($margin: 0) {
  margin: $margin;
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(sm);
}

@mixin sb-title-md($margin: 0) {
  margin: $margin;
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(md);
}

@mixin sb-title-lg($margin: 0) {
  margin: $margin;
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(lg);
}

@mixin sb-title-xl($margin: 0) {
  margin: $margin;
  font-family: $font-family-primary;
  font-size: get-font-size-mobile(xl);
}

@mixin sb-title-list-label($size, $color) {
  font-family: $font-family-primary;
  font-size: get-font-size-desktop($size);
  font-weight: 600;
  color: get-sb-color($color);
}

/// Text

/// Button

/// Old version
// Pseudos para hover en botones en PC
@mixin on-event($background, $amount, $color, $important: '') {
  background: darken($background, $amount) unquote($important);
  color: $color unquote($important);
  cursor: pointer unquote($important);
}

/// Button

/// Border

/// Old version
// genera borders por lado y a nivel global trabajando con los colores del mapa de paletas en ./variables
@mixin border($side, $width, $style, $color: null, $important: '') {
  @if $side== 'all' {
    border-width: $width unquote($important);
    border-style: $style unquote($important);
    border-color: get-sb-color($color) unquote($important);
  } @else {
    border-#{unquote($side)}-width: $width unquote($important);
    border-#{unquote($side)}-style: $style unquote($important);
    border-#{unquote($side)}-color: get-sb-color($color) unquote($important);
  }
}

/// Border

/// Border radius

/// Old version
// sin uso pero no borrar
@mixin borderRadius() {
  border-radius: 3px;
  border: 1px solid transparent;
}

/// Sportbook
@mixin border-radius($radius: sm) {
  border-radius: get-border-radius(#{$radius});
}

/// Border radius

/// Box shadow

/// Sportbook
@mixin box-shadow($horizontal, $vertical, $blur, $spread, $color) {
  box-shadow: unquote($horizontal + 'px' $vertical + 'px' $blur + 'px' $spread + 'px' $color);
}

/// Box shadow

/// Transform

/// Old version
@mixin transformProperty($duration, $styles: ()) {
  @each $p, $v in $styles {
    #{$p}: $v;
  }

  transition: $duration;
}

/// Transform

/// Transition

/// Old version
@mixin barTicket() {
  transition: all 0.2s cubic-bezier(0.97, 0, 0, 1);
  -webkit-transition: all 0.2s cubic-bezier(0.97, 0, 0, 1);
  -moz-transition: all 0.2s cubic-bezier(0.97, 0, 0, 1);
  -ms-transition: all 0.2s cubic-bezier(0.97, 0, 0, 1);
  -o-transition: all 0.2s cubic-bezier(0.97, 0, 0, 1);
}

@mixin transition($duration: $transition-timing--sm, $property: all, $timing-function: ease-in-out) {
  transition: $property $duration $timing-function;
}

/// Transition

/// Fallback

/// Old version
@mixin browser($browser) {
  @if $browser== 'safari' {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      // Safari only override
      ::i-block-chrome {
        @content;
      }
    }
  } @else if $browser== 'explorer' {
    @media screen {
      @content;
    }
  }
}

/// Old version
@mixin maintain-ratio($ratio: 1 1) {
  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));
  width: $width;
  height: 0;
  padding-bottom: $height;
}

/// Old version
@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
}

/// Fallback

/// Misc

/// Misc
