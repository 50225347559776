﻿#barcode {
  display: table;
  margin-top: 0;
  height: 120px;
  margin: 0 auto;
  padding: 0px;
}

#barcode li {
  display: table-cell;
  width: 1px;
  white-space: nowrap;
}

#barcode li.black {
  background: #000;
}

#barcode li.wide {
  width: 3px;
}

.barcodePopUp {
  padding: 0px;
  display: table;
  height: 135px;
  margin: 10px;

  li {
    display: table-cell;
    width: 1px;
    white-space: nowrap;
  }

  li.black {
    background: #000;
  }

  li.wide {
    width: 3px;
  }
}

.barcodeTarget {
  padding: 0px;
  width: 266px !important;
  height: 120px;
  margin: 0 auto;
  margin-top: 0;
  margin-bottom: 10px;

  ul {
    margin: 0;
    padding: 0;
    height: 100%;
  }
}

.barcode-alert {
  .alert-wrapper {
    max-width: 100%;
    align-self: flex-start;

    .alert-button-group {
      padding: 0;

      .alert-button {
        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 10px;
        border-radius: 3px !important;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        background-color: map-get($sb-colors, primary);
        width: 100%;

        .button-inner {
          justify-content: center !important;
        }
      }
    }
  }
}

.barcode.code128 {
  @include flex(flex, center, center, row, nowrap);
  min-height: 10px;
  height: 100%;
  min-height: 120px;
  margin-top: 0;
  white-space: normal;

  @for $i from 1 through 10 {
    .w#{$i},
    .b#{$i} {
      width: unquote($i + 'px');
    }
  }
}

.barcode.code128 > div.b {
  background: none repeat scroll 0 0 #000000;
  float: left;
  height: 100%;
}

.barcode.code128 > div.w {
  background: none repeat scroll 0 0 #ffffff;
  float: left;
  height: 100%;
}

.barcode.code128 .w20 {
  width: 20px;
}
